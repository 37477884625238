export const FULL_TIME_BENEFITS = {
  healthInsurance: 'Health Insurance',
  careerDevelopmentPrograms: 'Career Development Programs',
  annualBonuses: 'Annual Bonuses',
  employeeAssistancePrograms: 'Employee Assistance Programs (EAP)',
  wellnessPrograms: 'Wellness Programs',
  flexibleWorkArrangements: 'Flexible Work Arrangements',
  employeeStockOwnershipProgram: 'Employee Stock Ownership Program (ESOP)',
  salaryPackaging: 'Salary Packaging',
  novatedLeasing: 'Novated Leasing',
};

export const CONTRACTOR_BENEFITS = {
  remoteWorkOpportunities: 'Remote Work Opportunities',
  flexibleWorkArrangements: 'Flexible Work Arrangements',
};

export const BENEFITS = {
  ...FULL_TIME_BENEFITS,
  ...CONTRACTOR_BENEFITS,
};

export const getBenefitOptions = (roleType?: string) => {
  //Role type is only Contracting
  if (roleType === 'Contracting') {
    return Object.values(CONTRACTOR_BENEFITS).map((benefit) => ({
      label: benefit,
      value: benefit,
    }));
  }

  //Role type is Contracting along with other roles eg: Contracting;Full-time;Casual
  if (roleType?.includes('Contracting')) {
    return Object.values(BENEFITS).map((benefit) => ({
      label: benefit,
      value: benefit,
    }));
  }

  //Role type is not Contracting
  return Object.values(FULL_TIME_BENEFITS).map((benefit) => ({
    label: benefit,
    value: benefit,
  }));
};
