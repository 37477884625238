import { Arrow, TooltipPortal } from '@radix-ui/react-tooltip';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts';

import { CardContent } from '@/components/ui/card';
import { ChartConfig, ChartContainer } from '@/components/ui/chart';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { PersonalityArchetypeCode } from '@/services/candidate';
import { TPersonalityFactor } from '@/services/values-map';

export type TPersonalityChartData = Record<PersonalityArchetypeCode, number>;
interface PersonalityChartProps {
  data: TPersonalityChartData;
  data2?: TPersonalityChartData;
  factors: TPersonalityFactor[];
}
/**
 *
 * @param data: `{ H: number, E: number, X: number, C: number, A: number, O: number }`
 * @returns
 */
export const PersonalityChart: React.FC<PersonalityChartProps> = ({ data, data2, factors }) => {
  const codes: PersonalityArchetypeCode[] = [
    PersonalityArchetypeCode.H,
    PersonalityArchetypeCode.E,
    PersonalityArchetypeCode.X,
    PersonalityArchetypeCode.C,
    PersonalityArchetypeCode.A,
    PersonalityArchetypeCode.O,
  ];
  const chartData: { [key: string]: number | string }[] = [
    { personality: 'Honest-Humility', value: data[codes[0]] },
    { personality: 'Emotionality', value: data[codes[1]] },
    { personality: 'Extraversion', value: data[codes[2]] },
    { personality: 'Conscientiousness', value: data[codes[3]] },
    { personality: 'Agreeableness', value: data[codes[4]] },
    { personality: 'Openness', value: data[codes[5]] },
  ];

  if (data2) {
    chartData.forEach((item, index) => {
      const data2Key = codes[index];
      item['value2'] = data2[data2Key];
    });
  }

  const chartConfig = {
    value: {
      label: 'value',
    },
    value2: {
      label: 'value2',
    },
  } satisfies ChartConfig;

  const tickDimensions = [
    { width: 185, height: 32 },
    { width: 151, height: 32 },
    { width: 147, height: 32 },
    { width: 205, height: 32 },
    { width: 169, height: 32 },
    { width: 126, height: 32 },
  ];
  const tickPositions = [
    //+x moves left, +y moves up , -x moves right -y moves down
    { x: tickDimensions[0].width / 2, y: tickDimensions[0].height + 5 },
    { x: 0, y: tickDimensions[1].height + 5 },
    { x: 0, y: 0 },
    { x: tickDimensions[3].width / 2, y: -5 },
    { x: tickDimensions[4].width, y: 0 },
    { x: tickDimensions[5].width, y: tickDimensions[5].height + 5 },
  ];

  const tickDotColors = ['#3E60FF', '#FFDA53', '#D29CFE', '#FB4F21', '#2CB49E', '#FA8FDF'];

  const getToolTipContent = (index: number) => {
    const codes = ['H', 'E', 'X', 'C', 'A', 'O'];
    const factor = factors.find((factor) => factor.code === codes[index]);
    return factor;
  };

  return (
    <CardContent className="h-full w-full">
      <ChartContainer
        config={chartConfig}
        className="h-full w-full"
      >
        <RadarChart
          data={chartData}
          dataKey={'personality'}
          outerRadius={'83.5%'}
          id="personality-chart"
        >
          <PolarRadiusAxis
            domain={[-1, 6]}
            tickLine={false}
            tick={false}
            axisLine={false}
            tickCount={1}
          />
          <PolarGrid
            strokeWidth={1}
            stroke="#CED3DB"
            fillOpacity={0.3}
            className="fill-primary-dark-5"
          />
          <Radar
            dataKey="value"
            fillOpacity={0}
            stroke="#3E60FF" // primary-blue-100
            strokeWidth={4}
            isAnimationActive={false}
          />
          {data2 && (
            <Radar
              dataKey="value2"
              fillOpacity={0}
              stroke="#2CB49E" // primary-green-100
              strokeWidth={4}
              strokeDasharray={6}
              isAnimationActive={false}
            />
          )}
          <PolarAngleAxis
            dataKey="personality"
            tick={({ x, y, index }) => {
              const data = chartData[index];
              const tooltipContent = getToolTipContent(index);
              const tooltipSide = [1, 5].includes(index) ? 'bottom' : index === 0 ? 'right' : 'top';
              return (
                <TooltipProvider>
                  <Tooltip delayDuration={300}>
                    <TooltipTrigger asChild>
                      <g cursor={'pointer'}>
                        <rect
                          x={x - tickPositions[index].x}
                          y={y - tickPositions[index].y}
                          width={tickDimensions[index].width}
                          height={tickDimensions[index].height}
                          rx="6"
                          fill="#F3F4F6"
                        />
                        <rect
                          x={x - tickPositions[index].x}
                          y={y - 0.5 - tickPositions[index].y}
                          width={tickDimensions[index].width - 1}
                          height={tickDimensions[index].height - 1}
                          rx="5.5"
                          stroke="#E7E9ED"
                          fill="white"
                        />
                        <circle
                          cx={x + 16 - tickPositions[index].x}
                          cy={y + 16 - tickPositions[index].y}
                          r="3"
                          fill={tickDotColors[index]}
                        />
                        <text
                          x={x + 25 - tickPositions[index].x}
                          y={y + 22 - tickPositions[index].y}
                          textAnchor="start"
                          fontSize="18px"
                          stroke="#03122E"
                          strokeWidth={0.5}
                        >
                          {data.personality}
                        </text>
                      </g>
                    </TooltipTrigger>
                    <foreignObject overflow={'visible'}>
                      <TooltipPortal>
                        <TooltipContent
                          className="w-[320px] border-none shadow-[0px_12px_16px_0px_rgba(0,0,0,0.08)]"
                          collisionBoundary={document.getElementById('personality-chart')}
                          side={tooltipSide}
                          avoidCollisions
                        >
                          {tooltipContent && (
                            <>
                              <p className="mb-1 text-left text-xs font-semibold leading-[18px] text-primary-dark-100">
                                {tooltipContent.Name}
                              </p>
                              <p className="text-left text-xs font-medium leading-[18px] text-primary-dark-60">
                                Facets: {tooltipContent.Facets}
                                <br /> <br />
                                Adjectives: {tooltipContent.Adjectives}
                              </p>
                              <Arrow
                                width={16}
                                height={6}
                                className="fill-white"
                              />
                            </>
                          )}
                        </TooltipContent>
                      </TooltipPortal>
                    </foreignObject>
                  </Tooltip>
                </TooltipProvider>
              );
            }}
          />
        </RadarChart>
      </ChartContainer>
    </CardContent>
  );
};
