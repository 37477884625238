import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { createPortal } from 'react-dom';

import { cn } from '@/lib/utils';

interface IConfettiProps {
  className?: string;
  duration?: number;
  speed?: number;
  usePortal?: boolean;
}

const Confetti: React.FC<IConfettiProps> = ({ className, usePortal, duration = 5000, speed = 3 }) => {
  const renderConfetti = () => (
    <Fireworks
      autorun={{ speed, duration }}
      className={cn(className, usePortal && 'fixed left-0 top-0 z-[99] h-full w-full')}
    />
  );

  if (!usePortal) return renderConfetti();

  return createPortal(renderConfetti(), document.body);
};

export default Confetti;
