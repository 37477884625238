import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { Select } from '@/components/ui/select-new';
import Separator from '@/components/ui/separator';
import { Switch } from '@/components/ui/switch';
import { toast } from '@/components/ui/toaster';

import PermissionTooltip from '@/components/business/permisson-tooltip';
import FormFooter from '@/components/forms/form-footer';

import { useGetBusinessTeamMembersDetails, useGetCurrentBusiness, useUpdateBusinessTeamMember } from '@/hooks/business';
import {
  useGetBusinessMemberPermissions,
  useGetCurrentBusinessPermissions,
  useUpdateBusinessTeamMemberPermissions,
} from '@/hooks/permission';
import { useGetCurrentUser } from '@/hooks/user';

import { TBusinessUserPermission, TPermissionFormAllowancePolicy } from '@/services/permission';

import { accountTypeOptions, teamTypeOptions } from '@/constants/business-team';

import {
  businessUserPermissionSchema,
  BusinessUserPermissionSchema,
  teamMemberPermissionsSchema,
  TTeamMemberPermissionsFormData,
} from '@/validation-schemas/business/permissions';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/team/$profile/permissions')({
  component: TeamPermissions,
});

function TeamPermissions() {
  const { profile: memberId } = Route.useParams();
  const { data: currentUser } = useGetCurrentUser({});
  const { data: business } = useGetCurrentBusiness({});
  const { data: currentUserPermissions } = useGetCurrentBusinessPermissions();

  const { data: memberProfile } = useGetBusinessTeamMembersDetails({
    params: { path: { businessId: String(business!.id!), memberId } },
    reactQueryOptions: {
      enabled: Boolean(!!business && !!business?.id && !!memberId),
    },
  });

  const {
    data: permissions,
    isLoading: isPermissionLoading,
    refetch: refetchPermissions,
  } = useGetBusinessMemberPermissions({
    params: { path: { businessId: String(business!.id!), memberId } },
    reactQueryOptions: {
      enabled: Boolean(!!business && !!business?.id && !!memberId),
    },
  });

  const { mutateAsync: updateBusinessTeamMemberPermission, isPending } = useUpdateBusinessTeamMemberPermissions({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
  });

  const readyToFetchPermissions = !!business && !!business?.id && !!memberId;

  const isCurrentUser = currentUser?.id === parseInt(memberId);
  const isMemberOwner = memberProfile?.metadata?.account_type === 'owner';

  const handlePermissionSubmit = (data: Partial<BusinessUserPermissionSchema>) => {
    const updatedData = { ...permissions, ...data } as TBusinessUserPermission;
    updateBusinessTeamMemberPermission({
      params: {
        path: {
          businessId: business?.id.toString() as string,
          memberId: memberId,
        },
      },
      body: {
        manage_roles: updatedData.manage_roles,
        manage_pipelines: updatedData.manage_pipelines,
        manage_team_members: updatedData.manage_team_members,
        manage_partners: updatedData.manage_partners,
        manage_company_settings: updatedData.manage_company_settings,
      },
    });
  };

  useEffect(() => {
    if (readyToFetchPermissions) {
      refetchPermissions();
    }
  }, [readyToFetchPermissions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Separator />
      {/* Cannot self update */}
      <AccountTypeSection isViewOnly={isCurrentUser || !currentUserPermissions?.manage_team_members} />
      <Separator />
      {/* Can self update */}
      <TeamTypeSection isViewOnly={!currentUserPermissions?.manage_team_members} />
      <Separator />
      {/* Cannot self update */}
      <PermissionSection
        isPermissionSaving={isPending}
        permissions={permissions}
        onPermissionSubmit={handlePermissionSubmit}
        isPermissionLoading={isPermissionLoading}
        currentUserPermissions={currentUserPermissions}
        isViewOnly={isCurrentUser || isMemberOwner}
      />
    </>
  );
}

interface PermissionSectionProps {
  isViewOnly?: boolean;
  permissions?: TBusinessUserPermission;
  currentUserPermissions?: TBusinessUserPermission;
  onPermissionSubmit: (data: Partial<BusinessUserPermissionSchema>) => void;
  isPermissionSaving: boolean;
  isPermissionLoading: boolean;
}

function PermissionSection({
  isViewOnly,
  permissions,
  currentUserPermissions,
  isPermissionSaving,
  onPermissionSubmit,
}: PermissionSectionProps) {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="flex items-center gap-2 text-xl font-semibold leading-[30px] text-primary-dark-100">
          Permissions <PermissionTooltip />
        </h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">What is this user able to do?</p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <PermissionsForm
            isViewOnly={isViewOnly}
            permissionAllowancePolicy={{
              manage_company_settings: {
                isDisabled: Boolean(!currentUserPermissions?.manage_company_settings),
              },
              manage_pipelines: {
                isDisabled: Boolean(!currentUserPermissions?.manage_pipelines),
              },
              manage_roles: {
                isDisabled: Boolean(!currentUserPermissions?.manage_roles),
              },
              manage_team_members: {
                isDisabled: Boolean(!currentUserPermissions?.manage_team_members),
              },
              manage_partners: {
                isDisabled: Boolean(!currentUserPermissions?.manage_partners),
              },
            }}
            permissions={permissions}
            onPermissionSubmit={onPermissionSubmit}
            isPending={isPermissionSaving}
          />
        </Card>
      </div>
    </div>
  );
}

function TeamTypeSection({ isViewOnly }: { isViewOnly?: boolean }) {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Team</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          What Team/s does this user look after?
        </p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <TeamTypeForm isDisabled={isViewOnly} />
        </Card>
      </div>
    </div>
  );
}

function AccountTypeSection({ isViewOnly }: { isViewOnly?: boolean }) {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Account type</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          What level of of authority does this user have?
        </p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <AccountTypeForm isDisabled={isViewOnly} />
        </Card>
      </div>
    </div>
  );
}

interface IProfileDetailsFormProps {
  defaultValues?: TTeamMemberPermissionsFormData;
  isDisabled?: boolean;
}

function AccountTypeForm({ defaultValues, isDisabled }: IProfileDetailsFormProps) {
  const { data: business } = useGetCurrentBusiness({});
  const { profile: memberId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetails({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!business,
    },
  });
  const form = useForm<TTeamMemberPermissionsFormData>({
    resolver: zodResolver(teamMemberPermissionsSchema),
    defaultValues: {
      account_type: memberProfile?.metadata?.account_type ?? undefined,
    },
    values: defaultValues,
    disabled: isDisabled,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  const { mutateAsync: updateBusinessTeamMember, isPending } = useUpdateBusinessTeamMember({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Account type updated successfully');
      },
    },
  });

  // Use useEffect to reset the form when memberProfile changes
  useEffect(() => {
    if (memberProfile) {
      reset({
        account_type: memberProfile.metadata?.account_type ?? undefined,
      });
    }
  }, [memberProfile, reset]);

  const onSubmit: SubmitHandler<TTeamMemberPermissionsFormData> = (data) => {
    updateBusinessTeamMember({
      params: {
        path: {
          businessId: business?.id.toString() as string,
          memberId: memberId,
        },
      },
      body: {
        account_type: data.account_type as 'owner' | 'admin' | 'member' | 'view_only',
        team: memberProfile?.metadata?.team || null,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          disabled={isDisabled}
          control={control}
          name="account_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account type</FormLabel>
              <FormControl>
                <Select
                  disabled={field.disabled}
                  options={accountTypeOptions}
                  selected={field.value || null}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.account_type?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormFooter
        isDisabled={isDisabled}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isPending}
      />
    </FormProvider>
  );
}

function TeamTypeForm({ defaultValues, isDisabled }: IProfileDetailsFormProps) {
  const { data: business } = useGetCurrentBusiness({});
  const { profile: memberId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetails({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!business,
    },
  });
  const form = useForm<TTeamMemberPermissionsFormData>({
    resolver: zodResolver(teamMemberPermissionsSchema),
    defaultValues: {
      team: memberProfile?.metadata?.team?.split(';') || [],
    },
    values: defaultValues,
    disabled: isDisabled,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;
  const { mutateAsync: updateBusinessTeamMember, isPending } = useUpdateBusinessTeamMember({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Account type updated successfully');
      },
    },
  });

  // Use useEffect to reset the form when memberProfile changes
  useEffect(() => {
    if (memberProfile) {
      reset({
        team: memberProfile.metadata?.team?.split(';') ?? [],
      });
    }
  }, [memberProfile, reset]);

  const onSubmit: SubmitHandler<TTeamMemberPermissionsFormData> = (data) => {
    updateBusinessTeamMember({
      params: {
        path: {
          businessId: business?.id.toString() as string,
          memberId: memberId,
        },
      },
      body: {
        team: data?.team?.join(';') || null,
        account_type: memberProfile?.metadata?.account_type as 'owner' | 'admin' | 'member' | 'view_only',
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="team"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team</FormLabel>
              <FormControl>
                <MultiSelect
                  disabled={field.disabled}
                  options={teamTypeOptions.map((t) => ({
                    value: t,
                    label: t,
                  }))}
                  selected={field.value || []}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.team?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormFooter
        isDisabled={isDisabled}
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isPending}
      />
    </FormProvider>
  );
}

interface IPermissionsFormProps {
  isViewOnly?: boolean;
  isPending?: boolean;
  permissions?: TBusinessUserPermission;
  permissionAllowancePolicy?: TPermissionFormAllowancePolicy;
  onPermissionSubmit?: (data: Partial<BusinessUserPermissionSchema>) => void;
}

function PermissionsForm({
  isViewOnly,
  permissions,
  onPermissionSubmit,
  permissionAllowancePolicy,
  isPending,
}: IPermissionsFormProps) {
  const form = useForm<BusinessUserPermissionSchema>({
    resolver: zodResolver(businessUserPermissionSchema),
    defaultValues: permissions,
    values: permissions,
    disabled: isViewOnly,
  });

  const { control, handleSubmit } = form;

  const onSubmit: SubmitHandler<BusinessUserPermissionSchema> = (data) => {
    onPermissionSubmit?.(data);
  };

  return (
    <div className="flex flex-col space-y-4">
      <FormProvider {...form}>
        <Form>
          {/* <FormField
            control={control}
            name="manage_roles"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
                  <FormControl>
                    <Switch
                      disabled={permissionAllowancePolicy?.manage_roles.isDisabled}
                      checked={field?.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <span>Manage roles</span>
                </div>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="manage_pipelines"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
                  <FormControl>
                    <Switch
                      disabled={permissionAllowancePolicy?.manage_pipelines.isDisabled}
                      checked={field?.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <span>Manage pipeline</span>
                </div>
                <FormError />
              </FormItem>
            )}
          /> */}
          <FormField
            control={control}
            name="manage_team_members"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
                  <FormControl>
                    <Switch
                      disabled={permissionAllowancePolicy?.manage_team_members.isDisabled}
                      checked={field?.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <span>Manage team members</span>
                </div>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="manage_company_settings"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
                  <FormControl>
                    <Switch
                      disabled={permissionAllowancePolicy?.manage_company_settings.isDisabled}
                      checked={field?.value}
                      onCheckedChange={(checked) => {
                        field.onChange(checked);
                      }}
                    />
                  </FormControl>
                  <span>Manage company settings</span>
                </div>
                <FormError />
              </FormItem>
            )}
          />
        </Form>
        <FormFooter
          isDisabled={isViewOnly}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isPending}
        />
      </FormProvider>
    </div>
  );
}
