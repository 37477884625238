import { useEffect } from 'react';
import { createFileRoute, useLocation, useNavigate } from '@tanstack/react-router';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import FileUploaderWithDragAndDrop from '@/components/ui/file-uploader-drag-and-drop';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';

import { getFormattedDataForAPI } from '@/utils/format';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step8')({
  component: Step8,
});

type FormValues = {
  cv_url: string;
  web_url: string;
  linkedin_url: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.linkedin_url || values.linkedin_url === '') {
    errors.linkedin_url = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

function Step8() {
  const { data } = useGetCurrentCandidateSuspense({});

  const form = useForm<FormValues>({
    defaultValues: {
      web_url: '',
      linkedin_url: '',
      cv_url: '',
    },
    resolver,
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = form;
  const navigate = useNavigate();
  const currentRoute = useLocation({ select: (location) => location.pathname });

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: { onSuccess: () => navigate({ to: '/candidate/onboarding/completed', from: currentRoute }) },
  });

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        web_url: data.web_url || '',
        linkedin_url: data.linkedin_url || '',
        cv_url: data.cv_url || '',
      };
      reset(initialValues);
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateCandidate({ body: { ...getFormattedDataForAPI(data) } });
  };

  const handleFileUpload = (url: string, name: string) => {
    const cv_url = JSON.stringify({ url, name });
    setValue('cv_url', cv_url);
  };

  const getCurrentFile = () => {
    if (!data?.cv_url) return null;
    if (data.cv_url == '') return null;
    return JSON.parse(data?.cv_url);
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FileUploaderWithDragAndDrop
          acceptedFileTypes={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          keyPrefix={`candidate/${data?.id}`}
          onFileUpload={handleFileUpload}
          currentFile={getCurrentFile()}
          label="CV"
        />
        <FormField
          control={control}
          name="linkedin_url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Linkedin</FormLabel>
              <FormControl>
                <Input
                  id="linkedin"
                  prefix="https://"
                  className="w-full"
                  placeholder="www.linkedin.com/in/profilename"
                  {...field}
                  error={!!errors.linkedin_url?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="web_url"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Website / Portfolio (Optional)</FormLabel>
              <FormControl>
                <Input
                  className="w-full"
                  placeholder="www.myportfolio.com"
                  {...field}
                  error={!!errors.web_url?.message}
                  id="portfolio"
                  prefix="https://"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <OnboardingFooter
        isLoading={isUpdatingCandidate}
        handleNext={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
}
