import { UilBookmark } from '@iconscout/react-unicons';
import { QueryKey } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { Tag } from '@/components/ui/tag';
import { Toggle } from '@/components/ui/toggle';

import LocationTag from '@/components/tags/location-tag';
import MatchPercentLabel from '@/components/tags/match-percent-label';

import { useTogglePostSave } from '@/hooks/candidate';

import { CANDIDATE_ENDPOINTS, TCurrentCandidateMatch } from '@/services/candidate';

import { capitalizeFirstLetter } from '@/utils/string';

import RolesPlaceHolderImg from '@/assets/roles-placeholder.jpeg';

function UilBookmarkFilled() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="20"
      fill="none"
      viewBox="0 0 14 20"
    >
      <path
        fill="#6D7A92"
        d="M11 0H3a3 3 0 00-3 3v16a1 1 0 001.5.87L7 16.69l5.5 3.18a1 1 0 00.5.13 1 1 0 00.5-.13A1 1 0 0014 19V3a3 3 0 00-3-3zm1 17.27l-4.5-2.6a1 1 0 00-1 0L2 17.27V3a1 1 0 011-1h8a1 1 0 011 1v14.27z"
      ></path>
      <path
        fill="#6D7A92"
        d="M12 17.27l-4.5-2.6a1 1 0 00-1 0L2 17.27V3a1 1 0 011-1h8a1 1 0 011 1v14.27z"
      ></path>
    </svg>
  );
}

const RoleCard = ({
  role,
  showSaveButton,
  queryKey,
  isFromSavedPage,
}: {
  role: TCurrentCandidateMatch;
  showSaveButton?: boolean;
  queryKey: QueryKey;
  isFromSavedPage?: boolean;
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate({ to: `/candidate/role/${role.id}` });
  };

  const baseQueryKey = isFromSavedPage
    ? CANDIDATE_ENDPOINTS.GET_SAVED_POST
    : CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES;

  const { mutateAsync: togglePostSave } = useTogglePostSave({
    queryKey: [baseQueryKey, ...queryKey],
    save: !role.metadata?.is_saved,
    removeOnUnsave: isFromSavedPage,
  });

  const onSaveClick = () => {
    togglePostSave({
      params: {
        path: {
          postId: role.id.toString(),
        },
      },
    });
  };

  return (
    <Card className="space-y-4 p-4 shadow-md">
      <img
        src={role.cover_image_url || RolesPlaceHolderImg}
        alt="roles placeholder image"
        className="min-h-8 w-full cursor-pointer rounded-lg object-contain"
        onClick={handleClick}
      />
      <div className="flex justify-between py-4">
        <div className="flex items-center gap-x-4">
          <Avatar
            size={'xl'}
            fallbackType="company"
            src={role?.business?.logo_url}
          />
          <div className="flex flex-col gap-2">
            <h6
              className="cursor-pointer font-semibold"
              onClick={handleClick}
            >
              {role?.business?.name}
            </h6>
            <div className="flex items-center gap-2">
              <MatchPercentLabel percent={role.metadata?.match_percentage || 0} />
              {role?.metadata?.is_shortlisted && <Tag variant={'success'}>Shortlisted</Tag>}
            </div>
          </div>
        </div>
        {showSaveButton && (
          <Toggle
            pressed={!!role?.metadata?.is_saved}
            onPressedChange={onSaveClick}
          >
            {role?.metadata?.is_saved ? <UilBookmarkFilled /> : <UilBookmark className="default-icon" />}
          </Toggle>
        )}
      </div>
      <div className="space-y-2">
        <h6
          className="cursor-pointer font-semibold hover:underline"
          onClick={handleClick}
        >
          {role?.title}
        </h6>
        <div className="flex items-center gap-2">
          <LocationTag location={role.city || role.location || ''} />
          {role.workplace_type && <Tag variant={'primary'}>{capitalizeFirstLetter(role.workplace_type)}</Tag>}
          {role.employment_type && <Tag variant={'primary'}>{role.employment_type}</Tag>}
        </div>
      </div>
    </Card>
  );
};

export default RoleCard;
