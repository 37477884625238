import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDateRange(startDate: Date, endDate: Date): string {
  const dayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const day = dayFormatter.format(startDate);
  const date = dateFormatter.format(startDate);
  const startTime = timeFormatter.format(startDate);
  const endTime = timeFormatter.format(endDate);

  return `${day}, ${date} • ${startTime} - ${endTime}`;
}
