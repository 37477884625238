import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import Confetti from '@/components/ui/confetti';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

const RoleCreated = ({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) => {
  const { exitForm } = useCreateRoleStepper();
  return (
    <>
      <AlertDialog
        open={open}
        onOpenChange={onOpenChange}
      >
        {open && <Confetti usePortal />}

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle
              iconVariant="success"
              showCloseButton={false}
            >
              Congratulations!
            </AlertDialogTitle>
            <AlertDialogDescription>
              Your role has been successfully published. You can track the progress in your pipeline and make sure to
              stay up to date with your notifications.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogAction onClick={exitForm}>Thanks</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default RoleCreated;
