import { useMemo, useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useRouter } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import Avatar from '@/components/ui/avatar';
import { DataTable } from '@/components/ui/data-table';

import MatchPercentLabel from '@/components/tags/match-percent-label';
import { MatchStatusTag } from '@/components/tags/match-status-tag';

import { useGetCurrentRoleBusiness } from '@/hooks/business';
import { useFavouriteCandidate, useUnfavouriteCandidate } from '@/hooks/business-roles';
import { useGetCurrentDashboardURL } from '@/hooks/user';

import { TCandidateJobStatus } from '@/services/candidate';
import {
  PIPELINE_ENDPOINTS,
  TCandidateForPipelineBoard,
  TGetAllCandidatesForPipelineResponseForBoard,
} from '@/services/pipeline';
import { Filters } from '@/services/types';

import { getFullName } from '@/utils/string';

import FavoriteButton from './favorite-button';
import NextStepModal from './next-step-modal';
import OfferSentModal from './offer-sent-modal';
import ActionButton from './pipeline-action-button';
import RejectCandidateModal from './reject-candidate-modal';

interface IProps {
  data: TGetAllCandidatesForPipelineResponseForBoard;
  isLoading: boolean;
  setFilters: (filters: Filters) => void;
  paginationState: PaginationState;
  filters: Filters;
}

const BusinessPipelineTable = ({ data, isLoading, filters, setFilters, paginationState }: IProps) => {
  const queryClient = useQueryClient();
  const business = useGetCurrentRoleBusiness();
  const { data: selectedRoles } = usePipelineStore();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openNextStepModal, setOpenNextStepModal] = useState(false);
  const [openOfferSentModal, setOpenOfferSentModal] = useState(false);
  const [candidateCardId, setCandidateCardId] = useState<string | null>(null);

  const { mutate: favouriteCandidate } = useFavouriteCandidate({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        postId: '',
        userId: '',
      },
      query: {
        from_status: 'APPLIED',
      },
    },
  });
  const { mutate: unfavouriteCandidate } = useUnfavouriteCandidate({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        postId: '',
        userId: '',
      },
      query: {
        from_status: 'APPLIED',
      },
    },
  });

  const selectedCandidate = useMemo(
    () => data?.items?.find((item) => item.cardId === candidateCardId),
    [data, candidateCardId]
  );

  const router = useRouter();
  const backRoute = router.state.location.href;
  const dashboardUrl = useGetCurrentDashboardURL();

  function handleFavouriteClick(candidate: TGetAllCandidatesForPipelineResponseForBoard['items'][number]) {
    const tableQueryKey = [
      PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES,
      business?.id.toString(),
      filters,
      selectedRoles.map((item) => item.id),
    ];
    const isCandidateFavourited = candidate.metadata?.favourite_yn === 'Y';
    const candidateCurrentStatus = candidate.metadata?.candidate_status ?? 'APPLIED';
    queryClient.setQueryData(tableQueryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === candidate.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              favourite_yn: isCandidateFavourited ? 'N' : 'Y',
            },
          };
        }
        return item;
      }),
    }));

    const requestOptions = {
      params: {
        path: {
          businessId: business?.id.toString() as string,
          postId: candidate.job_post.id.toString(),
          userId: candidate.id.toString(),
        },
        query: {
          from_status: candidateCurrentStatus,
        },
      },
    };

    isCandidateFavourited ? unfavouriteCandidate(requestOptions) : favouriteCandidate(requestOptions);
  }

  const columns: ColumnDef<TCandidateForPipelineBoard>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessor: 'name',
        cell: ({ row }) => {
          return (
            <Link
              from={dashboardUrl}
              to={`./pipeline-candidate-profile/${row.original.job_post.id}/${row.original.id}/profile`}
              search={{
                backRoute,
              }}
            >
              <div className="flex items-center gap-2">
                <Avatar
                  src={row.original.image_url}
                  size="md"
                />
                <div className="max-w-[8.5rem] 3xl:max-w-[10.5rem]">
                  <p className="truncate text-sm font-semibold">{getFullName(row.original)}</p>
                  <p className="truncate text-xs text-primary-dark-40">{row.original.email}</p>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        header: 'Role',
        accessor: 'role',
        cell: ({ row }) => {
          return <div>{row.original.job_post.title}</div>;
        },
      },
      {
        header: 'Match',
        accessor: 'metadata.match_perc',
        cell: ({ row }) => {
          return (
            <MatchPercentLabel
              percent={row.original.metadata?.match_percentage || 0}
              hideLabel
            />
          );
        },
      },
      {
        header: 'Status',
        accessor: 'status',
        cell: ({ row }) => {
          return (
            <MatchStatusTag
              candidateMatchStatus={row.original.metadata?.candidate_status}
              jobPostStatus={row.original.job_post.status! as TCandidateJobStatus}
            />
          );
        },
      },
      {
        id: 'favorite',
        size: 24,
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <FavoriteButton
                onClick={() => {
                  handleFavouriteClick(row.original);
                }}
                isFavorite={row.original.metadata?.favourite_yn === 'Y' ? true : false}
              />
            </div>
          );
        },
      },
      {
        id: 'actions',
        size: 24,
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <ActionButton
                candidateId={row.original.id!}
                postId={row.original.job_post.id}
                onRejectClick={() => {
                  setOpenRejectModal(true);
                  setCandidateCardId(row.original.cardId!);
                }}
                onNextStepClick={() => {
                  setOpenNextStepModal(true);
                  setCandidateCardId(row.original.cardId!);
                }}
              />
            </div>
          );
        },
      },
    ],
    [filters, selectedRoles]
  );
  return (
    <div>
      <DataTable
        columns={columns}
        data={data?.items as TCandidateForPipelineBoard[]}
        isLoading={isLoading}
        noData={{
          title: 'No candidates available',
          description: 'You currently have no candidates available.',
        }}
        pagination={{
          state: paginationState,
          options: {
            rowCount: data?.total || 0,
            onPaginationChange: (pagination) => {
              setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
            },
          },
        }}
      />
      {candidateCardId && selectedCandidate && (
        <>
          <RejectCandidateModal
            open={openRejectModal}
            onOpenChange={setOpenRejectModal}
            // candidateId={candidateId}
            // postId={data[0].metadata!.post_id!}
          />
          <NextStepModal
            candidate={selectedCandidate}
            open={openNextStepModal}
            onOpenChange={setOpenNextStepModal}
            onInterviewSuccess={() => {
              setOpenNextStepModal(false);
            }}
            onOfferSuccess={() => {
              setOpenNextStepModal(false);
              setTimeout(() => {
                setOpenOfferSentModal(true);
              }, 200);
            }}
            onInterviewCancel={() => {
              setOpenNextStepModal(false);
            }}
            onOfferCancel={() => {
              setOpenNextStepModal(false);
            }}
          />
          <OfferSentModal
            open={openOfferSentModal}
            onOpenChange={setOpenOfferSentModal}
          />
        </>
      )}
    </div>
  );
};

export default BusinessPipelineTable;
