import { useRef } from 'react';
import { UilInfoCircle } from '@iconscout/react-unicons';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

function PermissionTooltip() {
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger
          ref={triggerRef}
          onClick={(event) => event.preventDefault()}
        >
          <UilInfoCircle className="h-5 w-5 text-primary-dark-60" />
        </TooltipTrigger>
        <TooltipContent
          onPointerDownOutside={(event) => {
            if (triggerRef.current?.contains(event.target as Node)) event.preventDefault();
          }}
          className="max-w-[320px] rounded-xl bg-primary-dark-100 px-3 py-2 text-xs text-primary-white-100"
        >
          <h4 className="pb-2 font-semibold leading-4">What are member permissions?</h4>
          <span className="font-medium leading-4">
            Toggling functions will enable Team Members to Add and Edit. Only Admins can delete or remove in the
            platform.
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default PermissionTooltip;
