import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@/components/ui/toaster';

import {
  CANDIDATE_ENDPOINTS,
  savePost,
  TCandidate,
  TCurrentCandidateMatches,
  TUpdateCurrentCandidateRequest,
  unsavePost,
  updateCurrentCandidate,
} from '@/services/candidate';
import { USER_ENDPOINTS } from '@/services/user';

import { UseMutationOptions, UseOptimisticMutationOptions } from '../types';

export function useUpdateCurrentCandidate({ reactQueryOptions }: UseMutationOptions<TUpdateCurrentCandidateRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateCurrentCandidate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE],
      });
      queryClient.invalidateQueries({
        queryKey: [USER_ENDPOINTS.GET_CURRENT_USER],
      });
      reactQueryOptions?.onSuccess?.();
    },
  });
}

export function useUpdateCandidateOptimistically({
  reactQueryOptions,
}: UseOptimisticMutationOptions<{ body: Partial<TCandidate> }, Error, { previousCandidate?: TCandidate }>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateCurrentCandidate,
    onMutate: async (newData) => {
      await queryClient.cancelQueries({ queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE] }); // Correct query key
      const previousCandidate = queryClient.getQueryData([CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE]); // Correct query key
      queryClient.setQueryData([CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE], (oldData: TCandidate) => ({
        ...oldData,
        ...newData.body,
      }));
      return { previousCandidate };
    },
    onError: (_, __, context) => {
      if (context?.previousCandidate) {
        queryClient.setQueryData([CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE], context.previousCandidate); // Correct query key
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE] }); // Correct query key
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE] }); // Correct query key
    },
  });
}

export const useTogglePostSave = ({
  queryKey,
  save,
  removeOnUnsave,
}: {
  queryKey: QueryKey;
  save: boolean;
  removeOnUnsave?: boolean;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: save ? savePost : unsavePost,
    onMutate: async (currentData) => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousJobPosts = queryClient.getQueryData(queryKey) as TCurrentCandidateMatches;

      const postId = parseInt(currentData.params.path.postId);
      if (removeOnUnsave && !save) {
        queryClient.setQueryData(queryKey, (oldData: TCurrentCandidateMatches) => {
          return {
            ...oldData,
            items: oldData.items.filter((item) => item.id !== postId),
          };
        });
      } else {
        queryClient.setQueryData(queryKey, (oldData: TCurrentCandidateMatches) => {
          return {
            ...oldData,
            items: oldData.items.map((item) =>
              item.id === postId
                ? {
                    ...item,
                    metadata: {
                      ...item.metadata,
                      is_saved: save,
                    },
                  }
                : item
            ),
          };
        });
      }

      return { previousJobPosts };
    },
    onError: (_, __, context) => {
      toast.error('Error saving role');
      if (context?.previousJobPosts) {
        queryClient.setQueryData(queryKey, context.previousJobPosts);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES],
        exact: false,
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: [CANDIDATE_ENDPOINTS.GET_SAVED_POST],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};
