import { Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import { AnalyticsCard } from '@/components/business/partner/analytics-card';
import FormLoader from '@/components/forms/form-loader';

import { PARTNER_ANALYTICS_TOOLTIPS } from '@/constants/partner-tooltips';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId/placements')({
  component: PartnerPlacementLayout,
});

function PartnerPlacementLayout() {
  return (
    <div>
      <Suspense fallback={<FormLoader />}>
        <div className="grid grid-cols-2 items-center justify-between gap-6 pb-8 3xl:grid-cols-4">
          <AnalyticsCard
            title="Active roles"
            description={PARTNER_ANALYTICS_TOOLTIPS.activeRoles}
            number={Math.round(Math.random() * 300)}
          />
          <AnalyticsCard
            title="Hired"
            description={PARTNER_ANALYTICS_TOOLTIPS.hired}
            number={Math.round(Math.random() * 300)}
          />
          <AnalyticsCard
            title="Time to fill"
            description={PARTNER_ANALYTICS_TOOLTIPS.timeToFill}
            number={`${Math.round(Math.random() * 30)} days`}
          />
          <AnalyticsCard
            title="Placement ratio"
            description={PARTNER_ANALYTICS_TOOLTIPS.placementRatio}
            number={`${Math.round(Math.random() * 30)} : ${Math.round(Math.random() * 300)}`}
          />
        </div>

        <Outlet />
      </Suspense>
    </div>
  );
}
