import { UilArrowGrowth, UilArrowUp } from '@iconscout/react-unicons';

import { Badge } from '@/components/ui/badge';

import { cn } from '@/lib/utils';

const MatchPercentLabel = ({
  percent,
  className,
  hideLabel,
}: {
  percent: number;
  className?: string;
  hideLabel?: boolean;
}) => {
  const getVariant = () => {
    if (percent >= 70) return 'success';
    return 'warning';
  };

  const getIcon = () => {
    if (percent >= 70) return <UilArrowUp className="size-4" />;
    return <UilArrowGrowth className="size-4" />;
  };

  return (
    <Badge
      hideDot
      variant={getVariant()}
      className={cn('pl-1.5 pr-2.5', className)}
    >
      {getIcon()}
      {percent}% {!hideLabel && 'match'}
    </Badge>
  );
};

export default MatchPercentLabel;
