import { createFileRoute, Outlet } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings/company')({
  component: SettingsLayout,
});

function SettingsLayout() {
  const navItems: ITabNavItem[] = [
    {
      label: 'Business',
      href: `/business/settings/company`,
    },
    {
      label: 'Details',
      href: `/business/settings/company/details`,
    },
    {
      label: 'Website & socials',
      href: `/business/settings/company/socials`,
    },
    {
      label: 'Bio & extras',
      href: `/business/settings/company/extras`,
    },
  ];
  return (
    <>
      <Heading
        title="Company Details"
        subtitle="Update your company details"
      />
      <TabNav
        navItems={navItems}
        className="mb-12 mt-8 border-b border-primary-dark-10"
        variant={'underlined'}
      />
      <Outlet />
    </>
  );
}
