import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AlertDialogCancel, AlertDialogFooter } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { Select } from '@/components/ui/select-new';

import { useGetCurrentBusiness, useInviteBusinessTeamMember } from '@/hooks/business';

import { TAccountTypeSelectOption, teamTypeOptions } from '@/constants/business-team';

import { inviteTeamSchema, TInviteTeamFormData } from '@/validation-schemas/business/invite-team-schema';

const accountTypeOptions = [
  {
    label: 'Admin',
    value: 'admin',
    description: 'Can change member permissions. Can invite team members to the company page.',
  },
  {
    label: 'Member',
    value: 'member',
    description:
      'Cannot change company page settings or invite team members. Permissions granted by Workspace Owner and/or Admin user.',
  },
  {
    label: 'View only',
    value: 'view_only',
    description:
      'Can view roles and pipeline only and no action(s) can be taken. Users will need to be promoted to Members by Workspace Owner and/or Admin users if further access is required.',
  },
];

export const InviteTeamMemberForm: React.FC<{ onInviteSent: () => void }> = ({ onInviteSent }) => {
  const { data: business } = useGetCurrentBusiness({});

  const form = useForm<TInviteTeamFormData>({
    resolver: zodResolver(inviteTeamSchema),
    defaultValues: {
      account_type: '',
      email: '',
      team: [],
      first_name: '',
      last_name: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const { mutate: inviteTeamMember, isPending: isInviteTeamMemberPending } = useInviteBusinessTeamMember({
    params: { path: { businessId: business?.id?.toString() ?? '' } },
    reactQueryOptions: {
      onSuccess: () => {
        onInviteSent();
      },
    },
  });

  const onSubmit: SubmitHandler<TInviteTeamFormData> = (data) => {
    inviteTeamMember({
      params: {
        path: {
          businessId: business?.id?.toString() ?? '',
        },
      },
      body: {
        email: data.email,
        team: data.team.join(';'),
        account_type: data.account_type as TAccountTypeSelectOption['value'],
        first_name: data.first_name,
        last_name: data.last_name,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form
        className="space-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex items-center gap-x-8">
          <FormField
            control={control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="First name"
                    {...field}
                    error={!!errors?.first_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Last name"
                    {...field}
                    error={!!errors?.last_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <FormField
          name="account_type"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account type</FormLabel>
              <FormControl>
                <Select
                  options={accountTypeOptions}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.account_type?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="email"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={!!errors?.email?.message}
                  placeholder="Email address"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="team"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team</FormLabel>
              <FormControl>
                <MultiSelect
                  options={teamTypeOptions.map((option) => ({ label: option, value: option }))}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.team?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            type="submit"
            loading={isInviteTeamMemberPending}
            loadingText="Sending invite"
          >
            Send invite
          </Button>
        </AlertDialogFooter>
      </Form>
    </FormProvider>
  );
};
