import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { Select } from '@/components/ui/select-new';
import Separator from '@/components/ui/separator';

// import { Switch } from '@/components/ui/switch';

import FormFooter from '@/components/forms/form-footer';

import { useGetBusinessTeamMembersDetailsSuspense } from '@/hooks/business';

import { getArrayFromString } from '@/utils/format';

import { accountTypeOptions } from '@/constants/business-team';

import { teamMemberPermissionsSchema, TTeamMemberPermissionsFormData } from '@/validation-schemas/business/permissions';

export const Route = createFileRoute(
  '/_authenticated/business/_dashboard/partners/members/$partnerId/$teamMemberId/permissions'
)({
  component: TeamPermissions,
});

function TeamPermissions() {
  return (
    <>
      <Separator />
      <AccountTypeSection />
      <Separator />
      <TeamTypeSection />
      {/* <Separator />
      <PermissionSection /> */}
    </>
  );
}

// function PermissionSection() {
//   return (
//     <div className="flex w-full space-x-8">
//       <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
//         <h3 className="flex items-center gap-2 text-xl font-semibold leading-[30px] text-primary-dark-100">
//           Permissions <PermissionTooltip />
//         </h3>
//         <p className="text-base font-medium leading-normal text-primary-dark-60">What is this user able to do?</p>
//       </div>
//       <div className="grow">
//         <Card className="p-8">
//           <PermissionsForm />
//         </Card>
//       </div>
//     </div>
//   );
// }

function TeamTypeSection() {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Team</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          What Team/s does this user look after?
        </p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <TeamTypeForm />
        </Card>
      </div>
    </div>
  );
}

function AccountTypeSection() {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Account type</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          What level of of authority does this user have?
        </p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <AccountTypeForm />
        </Card>
      </div>
    </div>
  );
}

function AccountTypeForm() {
  const { teamMemberId: memberId, partnerId: businessId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetailsSuspense({
    params: {
      path: {
        businessId: businessId.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!businessId,
    },
  });
  const form = useForm<TTeamMemberPermissionsFormData>({
    resolver: zodResolver(teamMemberPermissionsSchema),
    defaultValues: {
      account_type: memberProfile?.metadata?.account_type ?? undefined,
    },
    values: {
      account_type: memberProfile?.metadata?.account_type ?? undefined,
    },
    disabled: true,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const onSubmit: SubmitHandler<TTeamMemberPermissionsFormData> = (data) => {
    console.log(data);
  };
  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="account_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account type</FormLabel>
              <FormControl>
                <Select
                  disabled={field.disabled}
                  options={accountTypeOptions}
                  selected={field.value || ''}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.account_type?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={true}
      />
    </FormProvider>
  );
}

function TeamTypeForm() {
  const { teamMemberId: memberId, partnerId: businessId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetailsSuspense({
    params: {
      path: {
        businessId: businessId.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!businessId,
    },
  });
  const form = useForm<TTeamMemberPermissionsFormData>({
    resolver: zodResolver(teamMemberPermissionsSchema),
    defaultValues: {
      team: getArrayFromString(memberProfile?.metadata?.team || null),
    },
    values: {
      team: getArrayFromString(memberProfile?.metadata?.team || null),
    },
    disabled: true,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit: SubmitHandler<TTeamMemberPermissionsFormData> = (data) => {
    console.log(data);
  };

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="team"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team</FormLabel>
              <FormControl>
                <MultiSelect
                  disabled={field.disabled}
                  options={'Product,Design,HR,Admin'.split(',').map((s) => ({
                    label: s,
                    value: s,
                  }))}
                  selected={field.value || []}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.team?.message}
                  isLoading={false}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={true}
      />
    </FormProvider>
  );
}

// function PermissionsForm() {
//   return (
//     <div className="flex flex-col space-y-4">
//       <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
//         <Switch defaultChecked={false} />
//         <span>Manage roles</span>
//       </div>
//       <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
//         <Switch defaultChecked={false} />
//         <span>Manage pipeline</span>
//       </div>
//     </div>
//   );
// }
