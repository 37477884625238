import { useState } from 'react';
import { UilHeadphonesAlt, UilSuitcase, UilTrashAlt } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import Separator from '@/components/ui/separator';

import { useGetCurrentUserPermissions } from '@/hooks/permission';
import useAuth from '@/hooks/useAuth';

import { TBusiness } from '@/services/business/business.types';
import { TCandidateForPipeline } from '@/services/pipeline';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

import { formatDateRange } from '@/lib/utils';

import { MEETING_STATUS, TMeetingStatus } from '@/constants/meeting';

import NextStepModal from './next-step-modal';
import OfferSentModal from './offer-sent-modal';
import RejectCandidateModal from './reject-candidate-modal';
import { SetAvailabilityButton } from './set-availability-button';

const SidePanelCta = ({ candidate, business }: { candidate: TCandidateForPipeline; business: TBusiness }) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openNextStepModal, setOpenNextStepModal] = useState(false);
  const [openOfferSentModal, setOpenOfferSentModal] = useState(false);
  const { user } = useAuth();
  const { data: permissions } = useGetCurrentUserPermissions();

  const showSetAvailabilityButton = () => {
    if (!candidate.meeting) {
      return null;
    }

    if (
      candidate.metadata?.candidate_status === ROLE_CANDIDATE_STATUS.INTERVIEWING &&
      user?.app_user_id === candidate.meeting.owner_id
    ) {
      if (candidate.job_post.stages.length > 0) {
        const stageId = candidate.meeting.stage_id;
        const stageDuration = candidate.job_post.stages.find((stage) => stage.id === stageId)?.stage_duration;
        if (
          stageDuration &&
          (candidate.meeting.status === MEETING_STATUS.draft || candidate.meeting.status === MEETING_STATUS.pending)
        ) {
          return (
            <SetAvailabilityButton
              meeting_id={candidate.meeting.id}
              owner_id={candidate.meeting.owner_id}
              candidate_id={candidate.id}
              interval={Number(stageDuration)}
              meetingStatus={candidate.meeting.status as TMeetingStatus}
              buttonVariant="tertiary"
            />
          );
        }
        return null;
      }
    }
    return null;
  };

  const showMeetingTimeBox = () => {
    if (
      !candidate.meeting ||
      candidate.meeting.status !== MEETING_STATUS.scheduled ||
      !candidate.meeting.start_time ||
      !candidate.meeting.end_time
    ) {
      return null;
    }
    const post = candidate.job_post;
    const meeting = candidate.meeting;
    const stage = post.stages.find((stage) => stage.id === meeting.stage_id);
    const startTime = new Date(candidate.meeting.start_time);
    const endTime = new Date(candidate.meeting.end_time);
    const joinMeeting = () => {
      if (!meeting.meeting_link) return;
      window.open(meeting.meeting_link, '_blank', 'noopener,noreferrer');
    };
    return (
      <>
        <Separator size="xs" />
        <div className="inline-flex flex-col items-start justify-start gap-8 rounded-lg border border-[#a0e2c3] bg-[#f3fbf8] p-4">
          <div className="inline-flex items-start justify-start gap-4 self-stretch">
            <div className="flex h-12 w-12 items-center justify-center gap-2.5 rounded-lg bg-[#12b76a] px-1 py-0.5">
              <UilHeadphonesAlt className="text-primary-white-100" />
            </div>
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5">
              <div className="self-stretch text-base font-semibold leading-normal text-[#0c2149]">
                {post.title} • {stage?.stage_name}
              </div>
              <div className="text-xs font-medium leading-[18px] text-[#6d7a92]">
                {formatDateRange(startTime, endTime)}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
            <Button
              className="flex-1"
              size={'lg'}
              onClick={joinMeeting}
            >
              Join meeting
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {candidate.metadata?.candidate_status &&
        candidate.metadata?.candidate_status !== ROLE_CANDIDATE_STATUS.OFFER &&
        candidate.metadata?.candidate_status !== ROLE_CANDIDATE_STATUS.HIRED &&
        !!permissions?.manage_pipelines && (
          <div className="flex flex-col justify-center gap-4">
            {showSetAvailabilityButton()}
            <Button
              className="flex-grow"
              onClick={() => setOpenNextStepModal(true)}
            >
              <UilSuitcase className="size-[1.25rem]" /> Next Steps
            </Button>

            <Button
              variant="secondary-destructive"
              className="flex-grow"
              onClick={() => setOpenRejectModal(true)}
            >
              <UilTrashAlt className="size-[1.25rem]" /> Reject
            </Button>
            {showMeetingTimeBox()}
          </div>
        )}
      <RejectCandidateModal
        open={openRejectModal}
        onOpenChange={setOpenRejectModal}
        // candidateId={candidateId}
        // postId={data[0].application_meta!.post_id!}
      />
      <NextStepModal
        candidate={candidate}
        business={business}
        originalCandidateStatus={candidate.metadata?.candidate_status}
        open={openNextStepModal}
        onOpenChange={setOpenNextStepModal}
        onInterviewSuccess={() => {
          setOpenNextStepModal(false);
        }}
        onOfferSuccess={() => {
          setOpenNextStepModal(false);
          setTimeout(() => {
            setOpenOfferSentModal(true);
          }, 200);
        }}
        onInterviewCancel={() => {
          setOpenNextStepModal(false);
        }}
        onOfferCancel={() => {
          setOpenNextStepModal(false);
        }}
      />
      <OfferSentModal
        open={openOfferSentModal}
        onOpenChange={setOpenOfferSentModal}
      />
    </>
  );
};

export default SidePanelCta;
