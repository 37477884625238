import { UilLinkAlt } from '@iconscout/react-unicons';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Tag } from '@/components/ui/tag';

import LocationTag from '@/components/tags/location-tag';
import MatchPercentLabel from '@/components/tags/match-percent-label';
import { MatchStatusTag } from '@/components/tags/match-status-tag';

import { useGetCurrentRoleBusiness } from '@/hooks/business';
import { useGetCandidateDetailsForAPost } from '@/hooks/pipeline';

import { TCandidateJobStatus } from '@/services/candidate';
import { TCandidateDetails } from '@/services/pipeline';

import { formatCurrency } from '@/utils/currency';
import { capitalizeFirstLetter } from '@/utils/string';

import LinkedInIcon from '@/assets/linkedin-icon.svg';

import SidePanelCta from './side-panel-cta';

interface IProps {
  data: TCandidateDetails;
}

export function CandidateSidePanel({ data }: IProps) {
  const candidate = data.candidate_profile;
  const jobPost = data.job_post;
  const currentBusiness = useGetCurrentRoleBusiness();
  const { data: candidateDetails } = useGetCandidateDetailsForAPost({
    params: {
      path: {
        businessId: currentBusiness?.id.toString() ?? '',
        postId: jobPost.id.toString(),
        userId: candidate.user_id?.toString() ?? '',
      },
    },
    reactQueryOptions: {
      enabled: !!currentBusiness && !!jobPost.id && !!candidate.user_id,
    },
  });
  return (
    <Card className="w-full space-y-5 p-8 shadow-md 3xl:space-y-6">
      <div className="flex justify-between space-x-4">
        <Avatar
          src={data.image_url}
          size="3xl"
          variant="bordered"
        />
        <div className="flex gap-x-2">
          {candidate.linkedin_url && (
            <a
              href={`https://${candidate.linkedin_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="tertiary"
                size={'icon'}
              >
                <img
                  src={LinkedInIcon}
                  alt="Linkedin Icon"
                />
              </Button>
            </a>
          )}
          {candidate.web_url && (
            <a
              href={`https://${candidate.web_url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="tertiary"
                size={'icon'}
              >
                <UilLinkAlt className="h-[18px]" />
              </Button>
            </a>
          )}
        </div>
      </div>
      <h1 className="text-xl font-semibold text-primary-dark-100 3xl:text-2xl">
        {data?.first_name} {data?.last_name}
      </h1>
      <div className="space-y-2">
        <h2 className="text-sm font-semibold text-primary-dark-60 3xl:text-base">Match score</h2>
        <div>
          <MatchPercentLabel
            className="text-md"
            percent={data.metadata?.match_percentage || 0}
          />
        </div>
      </div>
      <div className="space-y-2 font-semibold">
        <h2 className="text-sm text-primary-dark-60 3xl:text-base">Availability</h2>
        <p className="text-base 3xl:text-lg">{candidate.availability}</p>
      </div>
      <div className="space-y-2">
        <h2 className="text-sm font-semibold text-primary-dark-60 3xl:text-base">Role</h2>
        <p className="text-base font-semibold 3xl:text-lg">{data?.job_post?.title}</p>
        <div className="flex gap-2">
          {data?.job_post.workplace_type && (
            <Tag variant="primary">{capitalizeFirstLetter(data?.job_post?.workplace_type)}</Tag>
          )}
          <Tag variant="primary">{data?.job_post?.employment_type}</Tag>
        </div>
      </div>
      <div className="space-y-2">
        <h2 className="text-sm font-semibold text-primary-dark-60 3xl:text-base">Role stage</h2>
        <MatchStatusTag
          candidateMatchStatus={data.metadata?.candidate_status}
          jobPostStatus={jobPost.status as TCandidateJobStatus}
        />
      </div>
      <div className="space-y-2">
        <h2 className="text-sm font-semibold text-primary-dark-60 3xl:text-base">Day rate range</h2>
        <p className="text-base font-semibold 3xl:text-lg">
          {formatCurrency(candidate.expectation_day_rate_from, candidate.expectation_currency)} -{' '}
          {formatCurrency(candidate.expectation_day_rate_to, candidate.expectation_currency)}
        </p>
      </div>
      <div className="space-y-2">
        <h2 className="text-sm font-semibold text-primary-dark-60 3xl:text-base">Location</h2>
        <LocationTag location={candidate.city || candidate.location} />
      </div>
      {candidateDetails && currentBusiness && (
        <SidePanelCta
          candidate={candidateDetails}
          business={currentBusiness}
        />
      )}
    </Card>
  );
}
