import { useState } from 'react';
import { UilArrowDown, UilArrowUp, UilInfoCircle } from '@iconscout/react-unicons';

import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { AreaChartWithGradient } from '@/components/charts/area-chart-with-gradient';

import { cn } from '@/lib/utils';

function generateRandomChartData(count: number = 12, max = 300) {
  return Array.from({ length: count }, () => ({
    number: Math.floor(Math.random() * max),
  }));
}

export function AnalyticsCard({
  title,
  number,
  description,
}: {
  title: string;
  number?: number | string;
  description?: string;
}) {
  const chartData = generateRandomChartData(12);

  const isTrendingUp = chartData[chartData.length - 1].number > chartData[chartData.length - 2].number;
  const trendingPercentage = Math.abs(
    Math.round(
      ((chartData[chartData.length - 1].number - chartData[chartData.length - 2].number) /
        chartData[chartData.length - 2].number) *
        100
    )
  );

  return (
    <Card className="flex-1 space-y-8 p-6 shadow-md">
      <CardTitle className="flex items-center gap-2 text-base">
        {title}
        <PartnerAnalyticsTooltip
          title={title}
          description={description}
        />
      </CardTitle>
      <CardContent className="flex items-center justify-between">
        <div className="flex flex-col justify-between text-primary-dark-100">
          <span className="text-4xl font-semibold leading-[48px]">{number || '--'}</span>
          <span className="inline-flex items-center gap-1 pt-2 text-xs font-medium leading-tight text-primary-dark-60 3xl:text-sm">
            <span
              className={cn(
                'inline-flex items-center gap-1 text-primary-success-100',
                !isTrendingUp && 'text-primary-alert-100'
              )}
            >
              {isTrendingUp ? <UilArrowUp className="size-5" /> : <UilArrowDown className="size-5" />}
              {trendingPercentage}%
            </span>
            vs last month
          </span>
        </div>
        <div className="w-[35%] min-w-[92px] max-w-[150px]">
          <AreaChartWithGradient
            chartData={chartData}
            isTrendingUp={isTrendingUp}
          />
        </div>
      </CardContent>
    </Card>
  );
}

function PartnerAnalyticsTooltip({ title, description }: { title: string; description?: string }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipProvider>
      <Tooltip
        onOpenChange={setIsOpen}
        open={isOpen}
      >
        <TooltipTrigger onClick={() => setIsOpen(!isOpen)}>
          <UilInfoCircle className="h-5 w-5 text-primary-dark-60" />
        </TooltipTrigger>
        <TooltipContent className="max-w-[320px] rounded-xl bg-primary-white-100 px-3 py-2 text-xs">
          <h4 className="pb-2 font-semibold leading-4 text-primary-dark-100">{title}</h4>
          <span className="font-medium leading-4 text-primary-dark-60">
            {description ||
              'This is a tooltip description. Descriptions can be more than one line and more than one sentence. Used to describe a feature.'}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
