type TProps = {
  primaryLabel?: string;
  showSecondLabel?: boolean;
};

export const ChartLabels: React.FC<TProps> = ({ showSecondLabel = true, primaryLabel = 'Your results' }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-4">
        <div className="flex h-8 w-8 shrink-0 items-center rounded border border-primary-dark-10 p-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="4"
            viewBox="0 0 24 4"
            fill="none"
            className="mx-auto flex"
          >
            <path
              d="M0 2H24"
              stroke="#3E60FF"
              strokeWidth="4"
            />
          </svg>
        </div>
        <p className="text-sm font-semibold leading-tight text-primary-dark-100">{primaryLabel}</p>
      </div>
      {showSecondLabel && (
        <div className="flex flex-row items-center gap-4">
          <div className="flex h-8 w-8 shrink-0 items-center rounded border border-primary-dark-10 p-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="4"
              viewBox="0 0 24 4"
              fill="none"
              className="mx-auto flex"
            >
              <path
                d="M0 2H24"
                stroke="#2CB49E"
                strokeWidth="4"
                strokeDasharray="1 2 2 2 3 2 2 2 3 2 2"
              />
            </svg>
          </div>
          <p className="text-sm font-semibold leading-tight text-primary-dark-100">Desired</p>
        </div>
      )}
    </div>
  );
};
