import { usePipelineStore } from '@/store/pipeline-store';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UilArrowUpRight, UilHeadphonesAlt, UilPlus } from '@iconscout/react-unicons';
import { useQueryClient } from '@tanstack/react-query';
import { Link, useRouter } from '@tanstack/react-router';
import { format } from 'date-fns';

import Avatar from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';

import { SetAvailabilityButton } from '@/components/pipeline/business/set-availability-button';
import MatchPercentLabel from '@/components/tags/match-percent-label';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useFavouriteCandidate, useUnfavouriteCandidate } from '@/hooks/business-roles';
import { useGetCurrentDashboardURL, useGetCurrentUser } from '@/hooks/user';

// import { TFavoriteCandidateQuery } from '@/services/business-roles';
import {
  PIPELINE_ENDPOINTS,
  TCandidateForPipelineBoard,
  TGetAllCandidatesForPipelineResponseForBoard,
} from '@/services/pipeline';

import { cn } from '@/lib/utils';

import FavoriteButton from './favorite-button';
import { DndElementType } from './types';

interface IProps {
  candidate: TCandidateForPipelineBoard;
  isViewOnly?: boolean;
}

const CandidateCard: React.FC<IProps> = ({ candidate, isViewOnly }) => {
  const { data: selectedRoles } = usePipelineStore();
  const { data: user } = useGetCurrentUser({});

  const selectedRolesIds = selectedRoles.map((item) => item.id);

  const queryKey = [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, ...selectedRolesIds];

  const { data: business } = useGetCurrentBusiness({});
  const queryClient = useQueryClient();
  const { mutate: favouriteCandidate } = useFavouriteCandidate({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        postId: '',
        userId: '',
      },
      query: {
        from_status: 'APPLIED',
      },
    },
    // reactQueryOptions: {
    //   onSuccess: () => {
    //     toast.success('Candidate favourited!');
    //   },
    // },
  });
  const { mutate: unfavouriteCandidate } = useUnfavouriteCandidate({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        postId: '',
        userId: '',
      },
      query: {
        from_status: 'APPLIED',
      },
    },
    // reactQueryOptions: {
    //   onSuccess: () => {
    //     toast.success('Candidate removed from favourites!');
    //   },
    // },
  });
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: candidate.cardId,
    data: {
      type: DndElementType.CARD,
    },
    disabled: isViewOnly,
  });

  const router = useRouter();
  const backRoute = router.state.location.href;
  const dashboardUrl = useGetCurrentDashboardURL();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleFavouriteClick() {
    const isCandidateFavourited = candidate.metadata?.favourite_yn === 'Y';
    const candidateCurrentStatus = candidate.metadata?.candidate_status ?? 'APPLIED';
    queryClient.setQueryData(queryKey, (oldData: TGetAllCandidatesForPipelineResponseForBoard) => ({
      ...oldData,
      items: oldData.items.map((item) => {
        if (item.cardId === candidate.cardId) {
          return {
            ...item,
            metadata: {
              ...item.metadata,
              favourite_yn: isCandidateFavourited ? 'N' : 'Y',
            },
          };
        }
        return item;
      }),
    }));

    const requestOptions = {
      params: {
        path: {
          businessId: business?.id.toString() as string,
          postId: candidate.job_post.id.toString(),
          userId: candidate.id.toString(),
        },
        query: {
          from_status: candidateCurrentStatus,
        },
      },
    };

    isCandidateFavourited ? unfavouriteCandidate(requestOptions) : favouriteCandidate(requestOptions);
  }

  const renderSetAvailabilityButton = () => {
    if (
      candidate.metadata?.candidate_status === 'INTERVIEWING' &&
      candidate.meeting &&
      (candidate.meeting.status === 'draft' || candidate.meeting.status === 'pending') &&
      user?.id === candidate.meeting.owner_id
    ) {
      const stage = candidate.job_post.stages.find((stage) => stage.id === candidate.meeting?.stage_id);

      if (!stage) return null;
      const interval = Number(stage.stage_duration);
      return (
        <div className="flex border-t border-primary-dark-5 pt-3">
          <SetAvailabilityButton
            meeting_id={candidate.meeting.id}
            owner_id={candidate.meeting.owner_id}
            interval={interval}
            meetingStatus={candidate.meeting.status}
            candidate_id={candidate.id}
          />
        </div>
      );
    }
    return null;
  };
  const renderScheduleMeetingTime = () => {
    if (
      candidate.meeting &&
      candidate.meeting.status === 'scheduled' &&
      candidate.meeting.start_time &&
      candidate.meeting.end_time
    ) {
      const startTime = new Date(candidate.meeting.start_time);
      const endTime = new Date(candidate.meeting.end_time);

      // Format strings
      const dayAndDateFormat = 'MMMM d'; // Format for day and date
      const timeFormat = 'h:mm a'; // Format for time in 12-hour format

      // Format the start date
      const formattedDayAndDate = format(startTime, dayAndDateFormat);
      const formattedStartTime = format(startTime, timeFormat);

      // Format the end time
      const formattedEndTime = format(endTime, timeFormat);
      return (
        <>
          <div className="flex items-center border-t border-primary-dark-5 pt-2">
            <div className="mr-2 flex items-center justify-center rounded-lg bg-primary-success-5 p-2">
              <UilHeadphonesAlt className="h-6 w-6 text-primary-success-100" />
            </div>

            <div className="flex flex-grow flex-col">
              <span className="text-xs font-medium text-primary-dark-80">{formattedDayAndDate}</span>
              <span className="text-xs font-medium text-primary-dark-40">
                {formattedStartTime} - {formattedEndTime}
              </span>
            </div>
            <button
              className="p-2"
              onClick={() => {
                if (candidate.meeting?.meeting_link) {
                  window.open(candidate.meeting.meeting_link, '_blank', 'noopener,noreferrer');
                }
              }}
            >
              <UilArrowUpRight className="h-5 w-5 text-primary-blue-100" />
            </button>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <Card
      className={cn(
        'relative p-4 shadow-md',
        isDragging && 'border-dashed border-primary-dark-40 bg-transparent shadow-none'
      )}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={cn('space-y-3', isDragging && 'opacity-0')}>
        <div className="flex justify-between">
          <Link
            from={dashboardUrl}
            to={`./pipeline-candidate-profile/${candidate.job_post.id}/${candidate.id}/profile`}
            search={{
              backRoute,
            }}
          >
            <div className="flex items-center gap-2">
              <Avatar
                src={candidate?.image_url}
                size="lg"
              />
              <div className="max-w-[8.5rem] 3xl:max-w-[10.5rem]">
                <p className="truncate text-lg font-semibold">{`${candidate.first_name} ${candidate.last_name}`}</p>
                <p className="truncate text-xs text-primary-dark-40">{candidate.email}</p>
              </div>
            </div>
          </Link>

          <FavoriteButton
            isFavorite={candidate.metadata?.favourite_yn === 'Y' ? true : false}
            onClick={handleFavouriteClick}
          />
        </div>
        <p className="text-sm text-primary-dark-40">{candidate.job_post.title}</p>
        <MatchPercentLabel
          percent={candidate.metadata?.match_percentage || 0}
          hideLabel
        />
        {renderSetAvailabilityButton()}
        {renderScheduleMeetingTime()}
      </div>
      {isDragging && (
        <div className={cn('absolute left-0 top-0 flex h-full w-full items-center justify-center')}>
          <UilPlus className="size-8 text-primary-dark-40" />
        </div>
      )}
    </Card>
  );
};

export default CandidateCard;
