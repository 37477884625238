import {
  UilLinkAlt,
  UilPause,
  UilPen,
  UilPlay,
  UilPower,
  UilSuitcase,
  UilTrafficBarrier,
  UilTrashAlt,
} from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';
import { toast } from '@/components/ui/toaster';

import { useGenerateRoleReferrerToken, useUpdateJobPost } from '@/hooks/business-roles/business-roles.mutations';
import { useGetCurrentBusiness } from '@/hooks/business/business.queries';
import { useGetCurrentUserPermissions } from '@/hooks/permission';
import { useGetCurrentDashboard } from '@/hooks/user';

import { addToClipBoardFromPromise } from '@/utils/clipboard';
import { getRoleDetailUrl, getRoleReferralUrl } from '@/utils/urls';

import { JOB_POST_STATUS } from '@/constants/post';

interface IProps {
  roleId: number;
  status: JOB_POST_STATUS;
  onDeleteClick: () => void;
  onCloseClick: () => void;
}

const RolesActionButton: React.FC<IProps> = ({ roleId, status, onDeleteClick, onCloseClick }) => {
  const navigate = useNavigate();
  const dashboard = useGetCurrentDashboard();
  const { data: permissions } = useGetCurrentUserPermissions();
  const { data: currentBusiness } = useGetCurrentBusiness({});

  const { mutate: updateJobPost } = useUpdateJobPost({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
  });

  const { mutateAsync: generateReferrerToken } = useGenerateRoleReferrerToken({
    params: {
      path: {
        businessId: currentBusiness?.id.toString() ?? '',
        postId: roleId.toString(),
      },
    },
  });

  const disabled = !permissions?.manage_roles;

  const onCopyLinkClick = async () => {
    addToClipBoardFromPromise(
      generateReferrerToken({
        params: {
          path: {
            businessId: currentBusiness?.id.toString() ?? '',
            postId: roleId.toString(),
          },
        },
      }),
      (data) => {
        return getRoleReferralUrl({
          dashboard,
          host: window.location.origin,
          roleId,
          referrerToken: data?.referrerToken || undefined,
        });
      }
    );
    // TODO: Move toast to the clipboard success callback
    toast.success('Link copied to clipboard');
  };

  const onEditClick = (id: number, status: JOB_POST_STATUS) => {
    const mode = status === JOB_POST_STATUS.DRAFT ? 'draft' : 'edit';

    navigate({ to: `/business/create-role`, search: { mode, id } });
  };

  const onPauseClick = () => {
    updateJobPost(
      {
        params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
        body: { status: JOB_POST_STATUS.HOLD },
      },
      {
        onSuccess: () => {
          toast.success('Job post paused');
        },
        onError: () => {
          toast.error('Failed to pause job post');
        },
      }
    );
  };

  const onOpenClick = () => {
    updateJobPost(
      {
        params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
        body: { status: JOB_POST_STATUS.OPEN },
      },
      {
        onSuccess: () => {
          toast.success('Job post opened');
        },
        onError: () => {
          toast.error('Failed to open job post');
        },
      }
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        {status !== JOB_POST_STATUS.DRAFT && (
          <DropdownMenuItem
            onClick={() =>
              navigate({
                to: getRoleDetailUrl({
                  dashboard,
                  roleId,
                }),
              })
            }
          >
            <UilSuitcase className="default-icon-sm" /> View role
          </DropdownMenuItem>
        )}
        {!disabled && (
          <DropdownMenuItem
            disabled={disabled}
            onClick={() => onEditClick(roleId, status)}
          >
            <UilPen className="default-icon-sm" />
            Edit
          </DropdownMenuItem>
        )}
        {status === JOB_POST_STATUS.OPEN && (
          <DropdownMenuItem onClick={onCopyLinkClick}>
            <UilLinkAlt className="default-icon-sm" />
            Copy Link
          </DropdownMenuItem>
        )}
        {status === JOB_POST_STATUS.OPEN && !disabled && (
          <DropdownMenuItem
            onClick={onPauseClick}
            disabled={disabled}
          >
            <UilPause className="default-icon-sm" />
            Pause
          </DropdownMenuItem>
        )}
        {status === JOB_POST_STATUS.HOLD && !disabled && (
          <DropdownMenuItem
            onClick={onOpenClick}
            disabled={disabled}
          >
            <UilPlay className="default-icon-sm" />
            Open
          </DropdownMenuItem>
        )}
        {status === JOB_POST_STATUS.CLOSED && !disabled && (
          <DropdownMenuItem
            onClick={onOpenClick}
            disabled={disabled}
          >
            <UilPower className="default-icon-sm" />
            Reopen
          </DropdownMenuItem>
        )}
        {(status === JOB_POST_STATUS.OPEN || status === JOB_POST_STATUS.HOLD) && !disabled && (
          <DropdownMenuItem
            onClick={onCloseClick}
            disabled={disabled}
          >
            <UilTrafficBarrier className="default-icon-sm" />
            Close
          </DropdownMenuItem>
        )}
        {status === JOB_POST_STATUS.DRAFT && !disabled && (
          <>
            <DropdownMenuItem
              onClick={onDeleteClick}
              disabled={disabled}
            >
              <UilTrashAlt className="default-icon-sm" />
              Delete
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RolesActionButton;
