import { createFileRoute } from '@tanstack/react-router';

import { Skeleton } from '@/components/ui/skeleton';

import BusinessProfileCard from '@/components/dashboard/business/business-profile-card';
import NewApplicantsCard from '@/components/dashboard/business/new-applicants-card';
import RolesDashboardTable from '@/components/dashboard/business/roles-dashboard-table';
import UpcomingMeetings from '@/components/dashboard/business/upcoming-meetings';
import SideBarSection from '@/components/dashboard/sidebar-section';

import { useGetCurrentUser } from '@/hooks/user';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/')({
  component: BusinessDashboardHome,
});

function BusinessDashboardHome() {
  const { data: user, isLoading } = useGetCurrentUser({});

  return (
    <main className="space-y-8">
      <div>
        {isLoading ? (
          <div className="space-y-2">
            <Skeleton className="h-7 w-60" />
            <Skeleton className="h-6 w-80" />
          </div>
        ) : (
          <>
            <h1 className="text-3xl font-semibold">Hello, {user?.first_name}</h1>
            <p className="text-sm text-primary-dark-60">Here’s what’s going on today!</p>
          </>
        )}
      </div>
      <div className="flex gap-8">
        <section className="flex-1">
          <RolesDashboardTable isForPartner />
        </section>
        <SideBarSection>
          <BusinessProfileCard />
          <NewApplicantsCard />
          <UpcomingMeetings />
        </SideBarSection>
      </div>
    </main>
  );
}
