import { z } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const publicRoleSchema = z
  .object({
    id: z.string().min(1, 'Role ID is required'),
    referrer: z.string().optional(),
  })
  .nullable();

export type TPublicRoleFormData = z.infer<typeof publicRoleSchema>;

type TPublicRoleStore = {
  data: TPublicRoleFormData;
  setData: (data: TPublicRoleFormData) => void;
  resetData: () => void;
};

const initialData: TPublicRoleFormData = null;

export const usePublicRoleStore = create<TPublicRoleStore>()(
  persist(
    (set) => ({
      data: initialData,
      setData: (data: TPublicRoleFormData) => set({ data }),
      resetData: () => set({ data: initialData }),
    }),
    {
      name: 'public-role-store',
    }
  )
);
