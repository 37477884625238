import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const profileDetailsSchema = z
  .object({
    first_name: z.string().min(1, 'First name is required'),
    last_name: z.string().min(1, 'Last name is required'),
    email: z.string({ required_error: 'Email is required' }).email('Invalid email address'),
    primary_phone: z.string().min(1, 'Phone number is required'),
    pronouns: z.string().min(1, 'Pronouns is required'),
  })
  .refine((data) => isValidPhoneNumber(data.primary_phone), {
    message: 'Invalid phone number',
    path: ['primary_phone'],
  });

export type TProfileDetailsFormData = z.infer<typeof profileDetailsSchema>;
