import { ReactNode, useState } from 'react';
import { UilAngleDown, UilCheck, UilTimes } from '@iconscout/react-unicons';

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';

import { cn } from '@/lib/utils';

import { Badge } from './badge';
import { inputVariants } from './input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export interface MultiSelectOption {
  label: string | ReactNode;
  value: string;
  description?: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  selected: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  error?: boolean;
  enableSearch?: boolean;
  isLoading?: boolean;
  maxSelections?: number;
  hideSelected?: boolean;
  disableAlphabeticalSorting?: boolean;
  disabled?: boolean;
}

export function MultiSelect({
  options,
  selected,
  onChange,
  placeholder = 'Select one or more',
  error,
  enableSearch,
  isLoading,
  maxSelections,
  hideSelected,
  disableAlphabeticalSorting = false,
  disabled,
}: MultiSelectProps) {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSelect = (value: string) => {
    const isAlreadySelected = selected.includes(value);
    const canSelectMore = !isAlreadySelected && (!maxSelections || selected.length < maxSelections);

    if (canSelectMore || isAlreadySelected) {
      const newValues = isAlreadySelected ? selected.filter((v) => v !== value) : [...selected, value];
      onChange(newValues);
    }

    if (enableSearch) {
      setSearchQuery('');
      setOpen(false);
    }

    if (!enableSearch) {
      setOpen(false);
    }
  };

  const isSelected = (value: string) => selected.includes(value);
  const sortedOptions = disableAlphabeticalSorting
    ? [...options]
    : [...options].sort((a, b) => {
        const valueA = typeof a.value === 'string' ? a.value : '';
        const valueB = typeof b.value === 'string' ? b.value : '';
        return valueA.localeCompare(valueB);
      });

  return (
    <div className="relative w-full">
      <Popover
        open={open}
        onOpenChange={setOpen}
      >
        <PopoverTrigger
          disabled={disabled}
          asChild
        >
          <button
            className={cn(
              inputVariants({ variant: error ? 'error' : 'default' }),
              'relative w-full justify-between text-primary-dark-60 [&[data-state=open]>svg]:rotate-180'
            )}
          >
            <span>{placeholder}</span>
            <UilAngleDown
              className={cn('pointer-events-none size-6 text-primary-dark-60 transition-transform duration-200')}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent
          className="PopoverContent p-0"
          align="start"
        >
          <Command className="w-full">
            {enableSearch && (
              <CommandInput
                placeholder="Search..."
                value={searchQuery}
                onValueChange={setSearchQuery}
              />
            )}
            <CommandList>
              <CommandEmpty>{isLoading ? 'Loading...' : 'No results found.'}</CommandEmpty>
              <CommandGroup>
                {sortedOptions.map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={() => handleSelect(option.value)}
                    className="flex items-center justify-between"
                    disabled={
                      maxSelections !== undefined && selected.length >= maxSelections && !isSelected(option.value)
                    }
                  >
                    <div>
                      <p>{option.label}</p>
                      {option.description && <p className="text-sm text-primary-dark-40">{option.description}</p>}
                    </div>
                    {isSelected(option.value) && <UilCheck className="size-5 text-primary-blue-100" />}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {selected.length > 0 && !hideSelected && (
        <div className="mt-2 flex flex-wrap gap-2">
          {selected.map((value) => {
            const option = options.find((o) => o.value === value);
            return (
              <Badge
                key={value}
                variant="success"
                hideDot
                onClick={() => {
                  if (!disabled) handleSelect(value);
                }}
                className="cursor-pointer"
              >
                <span>{option?.label || value}</span>
                <UilTimes className="ml-1 size-3.5 text-primary-success-80" />
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
}
