import { useState } from 'react';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

import FullPageLoader from '@/components/full-page-loader';
import RoleDetails from '@/components/roles/business/role-details/role-details';
import RoleDetailsSidePanel from '@/components/roles/business/role-details/roles-details-side-panel';
import { CultureMatch } from '@/components/roles/details/new-culture-breakdown-card';
import { PersonalityMatch } from '@/components/roles/details/new-personality-breakdown';

import { useGetCurrentPartnerBusiness } from '@/hooks/business';
import { useGetJobPostById } from '@/hooks/business-roles';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/role/$roleId')({
  component: RolePage,
});

function RolePage() {
  const { roleId } = Route.useParams();
  const router = useRouter();
  const { data: business, isPending: isBusinessPending } = useGetCurrentPartnerBusiness();
  const [showPersonalityBreakdown, setShowPersonalityBreakdown] = useState(false);
  const [showCultureBreakdown, setShowCultureBreakdown] = useState(false);

  const togglePersonalityBreakdown = () => {
    setShowPersonalityBreakdown(!showPersonalityBreakdown);
    setShowCultureBreakdown(false);
    window.scrollTo(0, 0);
  };

  const toggleCultureBreakdown = () => {
    setShowCultureBreakdown(!showCultureBreakdown);
    setShowPersonalityBreakdown(false);
    window.scrollTo(0, 0);
  };

  const { data: role, isPending } = useGetJobPostById({
    params: {
      path: {
        businessId: business!.id.toString(),
        postId: roleId,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
    },
  });

  if (isPending || isBusinessPending) return <FullPageLoader className="w-full" />;

  if (!role)
    return (
      <Card className="w-full">
        <CardContent>
          <CardTitle>Role not found</CardTitle>
        </CardContent>
      </Card>
    );

  const renderDetails = () => {
    if (showPersonalityBreakdown && role.personality_profile) {
      return <PersonalityMatch rolePersonalityProfile={role.personality_profile} />;
    }
    if (showCultureBreakdown && role.culture_profile) {
      return <CultureMatch roleCultureProfile={role.culture_profile} />;
    }
    return <RoleDetails role={role} />;
  };

  return (
    <main className="space-y-6">
      <Button
        variant="tertiary"
        onClick={() => {
          if (showPersonalityBreakdown || showCultureBreakdown) {
            setShowPersonalityBreakdown(false);
            setShowCultureBreakdown(false);
          } else {
            router.history.back();
          }
        }}
      >
        <UilArrowLeft size={18} />
        Back
      </Button>
      <div className="flex gap-8">
        <section className="sticky top-8 h-[calc(100vh-48px)] w-full max-w-[320px] flex-shrink-0 2xl:max-w-[380px] 3xl:max-w-[460px]">
          <ScrollArea className="h-full">
            <RoleDetailsSidePanel
              role={role}
              showCultureBreakdown={showCultureBreakdown}
              showPersonalityBreakdown={showPersonalityBreakdown}
              toggleCultureBreakdown={toggleCultureBreakdown}
              togglePersonalityBreakdown={togglePersonalityBreakdown}
            />
          </ScrollArea>
        </section>
        <section className="flex-grow">{renderDetails()}</section>
      </div>
    </main>
  );
}
