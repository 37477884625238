import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@/components/ui/toaster';

import useAuth from '@/hooks/useAuth';

import {
  deactivateAccount,
  reactivateAccount,
  sendReactivationEmail,
  TReactivateAccountRequest,
  TUPdateCurrentUserPasswordRequest,
  TUpdateCurrentUserRequest,
  updateCurrentUser,
  updateCurrentUsersPassword,
  USER_ENDPOINTS,
} from '@/services/user';

import { UseMutationOptions } from '../types';

export function useUpdateCurrentUsersPassword({
  reactQueryOptions,
}: UseMutationOptions<TUPdateCurrentUserPasswordRequest>) {
  const { logout } = useAuth();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateCurrentUsersPassword,
    onSuccess: () => {
      toast.success('Password updated successfully!');
      logout();
      reactQueryOptions?.onSuccess?.();
    },
  });
}

export function useUpdateCurrentUser({ reactQueryOptions }: UseMutationOptions<TUpdateCurrentUserRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateCurrentUser,
    onSuccess: () => {
      toast.success('Account updated successfully!');
      queryClient.invalidateQueries({
        queryKey: [USER_ENDPOINTS.GET_CURRENT_USER],
      });
      reactQueryOptions?.onSuccess?.();
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });
}

export function useDeactivateAccount({ reactQueryOptions }: UseMutationOptions<TUpdateCurrentUserRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: deactivateAccount,
    onSuccess: () => {
      toast.success('Account deactivated successfully!');
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      toast.error('Failed to deactivate account');
    },
  });
}

export function useSendReactivationEmail({ reactQueryOptions }: UseMutationOptions<TUpdateCurrentUserRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: sendReactivationEmail,
    onError: () => {
      toast.error('Failed to send reactivation email');
    },
  });
}

export function useReactivateAccount({ reactQueryOptions }: UseMutationOptions<TReactivateAccountRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: reactivateAccount,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
  });
}
