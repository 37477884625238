import { usePublicRoleStore } from '@/store/public-role-store';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FullPageLoader from '@/components/full-page-loader';
import FeaturedIcon from '@/components/icons/featured-icon';
import { Logo } from '@/components/logo';
import RoleDetails from '@/components/roles/business/role-details/role-details';

import { useGetPostByIdPublicly } from '@/hooks/post';

type TRoleReferrer = {
  referrer?: string;
};

export const Route = createFileRoute('/role/$roleId')({
  component: RoleDetail,
  validateSearch: (search: Record<string, unknown>): TRoleReferrer => {
    const referrer = search.referrer as string | undefined;
    return {
      referrer,
    };
  },
});

function RoleDetail() {
  const { roleId } = Route.useParams();

  const { data: post, isLoading } = useGetPostByIdPublicly({
    params: { path: { postId: roleId } },
    reactQueryOptions: {
      enabled: !!roleId,
    },
  });

  if (isLoading || !post) {
    return <FullPageLoader />;
  }

  return (
    <div className="px-8 py-12">
      <main className="flex space-x-8">
        <section className="w-[480px] max-w-[780px] flex-shrink-0 xl:w-[480px] 2xl:w-[640px] 3xl:w-[780px]">
          <SidePanel />
        </section>
        <section className="flex flex-1 flex-col space-y-8">
          <RoleDetails role={post} />
        </section>
      </main>
    </div>
  );
}

function SidePanel() {
  const { roleId } = Route.useParams();
  const search = Route.useSearch();
  const navigate = useNavigate();

  const { setData: setPublicRole } = usePublicRoleStore();

  return (
    <div className="inline-flex flex-col items-start justify-start">
      <Logo
        variant="full"
        className="mb-2"
      />
      <div className="flex flex-col items-start justify-center gap-4 self-stretch py-4">
        <div className="self-stretch text-base font-medium leading-normal text-primary-dark-60">
          Thank you for your interest in this position.
          <br />
          <br />
          We’re using Upplft to match candidates with roles based not only on their capabilities but also on their
          alignment with our culture and values.
        </div>
      </div>
      <div className="flex flex-col space-y-4 py-4">
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Match roles based on personality, values and culture alignment
          </span>
        </div>
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Gain insights into what a company is really looking for
          </span>
        </div>
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            No arduous CV uploads, we making applying for roles super simple
          </span>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center space-y-6 self-stretch py-4">
        <div className="self-stretch text-lg font-semibold leading-7 text-primary-dark-100">
          Don’t have an Upplft account? Sign up or sign in to apply
        </div>
        <div className="flex w-full flex-col space-y-6">
          <Button
            className="w-full"
            onClick={() => {
              setPublicRole({ id: roleId, referrer: search.referrer });
              navigate({ to: '/signup' });
            }}
          >
            Sign up
          </Button>
          <Button variant={'tertiary'}>
            <Link
              className="w-full"
              to={`/candidate/role/$roleId`}
              params={{ roleId }}
              search={search}
            >
              Sign in to Apply
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
