export enum NOTIFICATION_TYPES {
  ALL = 'all',
  ROLES = 'roles',
  CALENDAR = 'calendar',
}

export enum NOTIFICATION_STATUSES {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read',
}

export enum NOTIFICATION_ACTIONS {
  ALL = 'all',
  MARK_ALL_AS_READ = 'mark_all_as_read',
  MARK_AS_READ = 'mark_as_read',
}

export const notifications = [
  {
    id: 1,
    type: NOTIFICATION_TYPES.CALENDAR,
    title: 'Calendar Notification',
    description: 'Calendar description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 2,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 3,
    type: NOTIFICATION_TYPES.CALENDAR,
    title: 'Calendar Notification',
    description: 'Calendar description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 4,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 5,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 6,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 7,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 8,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.READ,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 9,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 10,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 11,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 12,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 13,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 14,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 15,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
  {
    id: 16,
    type: NOTIFICATION_TYPES.ROLES,
    title: 'ROle Notification title',
    description: 'role notification description',
    status: NOTIFICATION_STATUSES.UNREAD,
    action: NOTIFICATION_ACTIONS.ALL,
    createdAt: new Date(),
  },
];

export const TNotification = typeof notifications[0];

export const getNotificationsByType = (type: NOTIFICATION_TYPES) => {
  if (type === NOTIFICATION_TYPES.ALL) {
    return notifications;
  }
  return notifications.filter((notification) => notification.type === type);
};

export enum NOTIFICATION_TYPE {
  WEB = 'web',
  EMAIL = 'email',
  SMS = 'sms',
}

export enum NOTIFICATION_TOPIC {
  PRODUCT = 'product',
  ROLES = 'roles',
  CALENDAR = 'calendar',
  PIPELINE = 'pipeline',
  TEAM = 'team',
  PARTNERS = 'partners',
}

export enum NOTIFICATION_SUBTOPIC {
  FEATURES = 'features',
  UPDATES = 'updates',
  BUGS = 'bugs',
  MATCHES = 'matches',
  SHORTLISTED = 'shortlisted',
  PUBLISHED = 'published',
  PAUSED = 'paused',
  CLOSED = 'closed',
  APPLICATIONS = 'applications',
  INTERVIEWS = 'interviews',
  HIRED = 'hired',
  INVITATIONS = 'invitations',
  NOTES = 'notes',
  REQUESTS = 'requests',
  ASSESSMENTS = 'assessments',
  ROLE_CLOSED = 'role_closed',
}

export enum NOTIFICATION_EVENT_TYPES {
  FEATURES = 'features',
  UPDATES = 'updates',
  BUGS = 'bugs',
  MATCHES = 'matches',
  SHORTLISTED = 'shortlisted',
  PUBLISHED = 'published',
  PAUSED = 'paused',
  CLOSED = 'closed',
  APPLICATIONS = 'applications',
  NEW_APPLICATION = 'new_application',
  CANDIDATE_REJECTED = 'candidate_rejected',
  CANDIDATE_REJECTED_CONNECTION = 'candidate_rejected_connection',
  CANDIDATE_ACCEPTED_CONNECTION = 'candidate_accepted_connection',
  INTERVIEWS = 'interviews',
  MEETING_OWNER_ASSIGNED = 'meeting_owner_assigned',
  CANDIDATE_INTERVIEW_TIMES_RECEIVED = 'candidate_interview_times_received',
  CANDIDATE_BOOKED_INTERVIEW = 'candidate_booked_interview',
  NEXT_STAGE = 'next_stage',
  REQUEST_RESCHEDULE_INTERVIEW = 'request_reschedule_interview',
  CANDIDATE_RESCHEDULED_INTERVIEW = 'candidate_rescheduled_interview',
  CANDIDATE_CANCELLED_INTERVIEW = 'candidate_cancelled_interview',
  OWNER_CANCELLED_INTERVIEW = 'owner_cancelled_interview',
  OFFERED = 'offered',
  HIRED = 'hired',
  INVITATIONS = 'invitations',
  NOTES = 'notes',
  REQUESTS = 'requests',
  ASSESSMENTS = 'assessments',
  ROLE_CLOSED = 'role_closed',
}

export const NotificationTypeMap = {
  [NOTIFICATION_TYPE.WEB]: 'Platform',
  [NOTIFICATION_TYPE.EMAIL]: 'Email',
  [NOTIFICATION_TYPE.SMS]: 'SMS',
};

export const NotificationTopicLabelMap = {
  [NOTIFICATION_TOPIC.PRODUCT]: 'Product',
  [NOTIFICATION_TOPIC.ROLES]: 'Roles',
  [NOTIFICATION_TOPIC.CALENDAR]: 'Calendar',
};

export const CandidateNotificationTopicMap = {
  [NOTIFICATION_TOPIC.PRODUCT]: {
    [NOTIFICATION_SUBTOPIC.FEATURES]: 'Features',
    [NOTIFICATION_SUBTOPIC.UPDATES]: 'Updates',
    [NOTIFICATION_SUBTOPIC.BUGS]: 'Bugs',
  },
  [NOTIFICATION_TOPIC.ROLES]: {
    [NOTIFICATION_SUBTOPIC.MATCHES]: 'Matches',
    [NOTIFICATION_SUBTOPIC.SHORTLISTED]: 'Shortlisted',
    [NOTIFICATION_SUBTOPIC.INTERVIEWS]: 'Interviews',
    [NOTIFICATION_SUBTOPIC.ASSESSMENTS]: 'Assessments',
    [NOTIFICATION_SUBTOPIC.ROLE_CLOSED]: 'Role Closed',
    [NOTIFICATION_SUBTOPIC.HIRED]: 'Hired',
  },
  [NOTIFICATION_TOPIC.CALENDAR]: {
    [NOTIFICATION_SUBTOPIC.INVITATIONS]: 'Invitations',
    [NOTIFICATION_SUBTOPIC.INTERVIEWS]: 'Interviews',
  },
};

export const BusinessNotificationTopicMap = {
  [NOTIFICATION_TOPIC.PRODUCT]: {
    [NOTIFICATION_SUBTOPIC.FEATURES]: 'Features',
    [NOTIFICATION_SUBTOPIC.UPDATES]: 'Updates',
    [NOTIFICATION_SUBTOPIC.BUGS]: 'Bugs',
  },
  [NOTIFICATION_TOPIC.ROLES]: {
    [NOTIFICATION_SUBTOPIC.PUBLISHED]: 'Published',
    [NOTIFICATION_SUBTOPIC.PAUSED]: 'Paused',
    [NOTIFICATION_SUBTOPIC.CLOSED]: 'Closed',
  },
  [NOTIFICATION_TOPIC.PIPELINE]: {
    [NOTIFICATION_SUBTOPIC.APPLICATIONS]: 'Applications',
    [NOTIFICATION_SUBTOPIC.SHORTLISTED]: 'Shortlisted',
    [NOTIFICATION_SUBTOPIC.INTERVIEWS]: 'Interviews',
    [NOTIFICATION_SUBTOPIC.HIRED]: 'Hired',
  },
  [NOTIFICATION_TOPIC.CALENDAR]: {
    [NOTIFICATION_SUBTOPIC.INTERVIEWS]: 'Interviews',
  },
  [NOTIFICATION_TOPIC.TEAM]: {
    [NOTIFICATION_SUBTOPIC.INVITATIONS]: 'Invitations',
    [NOTIFICATION_SUBTOPIC.NOTES]: 'Notes',
    [NOTIFICATION_SUBTOPIC.REQUESTS]: 'Requests',
  },
  [NOTIFICATION_TOPIC.PARTNERS]: {
    [NOTIFICATION_SUBTOPIC.INVITATIONS]: 'Invitations',
    [NOTIFICATION_SUBTOPIC.NOTES]: 'Notes',
    [NOTIFICATION_SUBTOPIC.REQUESTS]: 'Requests',
  },
};

export const NotificationTopicDescriptionMap = {
  [NOTIFICATION_TOPIC.PRODUCT]: 'These are notifications for when there is any product updates or known bugs.',
  [NOTIFICATION_TOPIC.ROLES]: 'These are notifications for when there is any activity regarding your roles.',
  [NOTIFICATION_TOPIC.PIPELINE]: 'These are notifications for when there is any activity regarding your pipeline.',
  [NOTIFICATION_TOPIC.CALENDAR]: 'These are notifications for when there is any activity regarding your calendar.',
  [NOTIFICATION_TOPIC.TEAM]: 'These are notifications for when there is any activity regarding your team.',
  [NOTIFICATION_TOPIC.PARTNERS]: 'These are notifications for when there is any activity regarding your partners.',
};

export const NotificationSettingsDescription =
  'These are your overarching notification settings.\n Please be aware that we may send you important notifications regardless of your settings.';

export enum NotificationTabFilterOptionEnum {
  ALL = 'all',
  ROLES = NOTIFICATION_TOPIC.ROLES,
  CALENDAR = NOTIFICATION_TOPIC.CALENDAR,
}

export const getNotificationTabFilterOptionEnumFromValue = (value: string) => {
  switch (value) {
    case NOTIFICATION_TOPIC.ROLES:
      return NotificationTabFilterOptionEnum.ROLES;
    case NOTIFICATION_TOPIC.CALENDAR:
      return NotificationTabFilterOptionEnum.CALENDAR;
    default:
      return NotificationTabFilterOptionEnum.ALL;
  }
};
