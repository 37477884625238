import { UilArrowUpRight } from '@iconscout/react-unicons';
import { Link } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCurrentDashboardURL } from '@/hooks/user';

const BusinessProfileCard = () => {
  const { data: business, isPending } = useGetCurrentBusiness({});
  const dashboardUrl = useGetCurrentDashboardURL();

  if (isPending) return <Skeleton className="h-[9.125rem]" />;
  return (
    <Card className="flex items-center justify-between gap-4">
      <div className="-ml-2.5 flex items-center gap-4">
        <Avatar
          src={business?.logo_url}
          variant="bordered"
          size="3xl"
          fallbackType="company"
        />
        <div className="font-semibold">
          <p className="text-sm text-primary-dark-40">YOUR ORGANISATION</p>
          <p className="text-xl">{business?.name}</p>
        </div>
      </div>
      <Link
        from={dashboardUrl}
        to="./settings/company"
      >
        <Button
          variant="ghost"
          className="!p-3"
        >
          <UilArrowUpRight className="size-[1.125rem] text-primary-blue-100" />
        </Button>
      </Link>
    </Card>
  );
};

export default BusinessProfileCard;
