import { UilSuitcase, UilTrashAlt, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate, useRouter } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

import { useGetCurrentUserPermissions } from '@/hooks/permission';
import { useGetCurrentDashboardURL } from '@/hooks/user';

interface IProps {
  candidateId: number;
  postId: number;
  onRejectClick: (candidateId: number) => void;
  onNextStepClick: (candidateId: number) => void;
}

const PipelineActionButton: React.FC<IProps> = ({ candidateId, postId, onRejectClick, onNextStepClick }) => {
  const navigate = useNavigate();
  const router = useRouter();
  const backRoute = router.state.location.href;

  const { data: permissions } = useGetCurrentUserPermissions();
  const dashboardUrl = useGetCurrentDashboardURL();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() =>
            navigate({
              from: dashboardUrl,
              to: `./pipeline-candidate-profile/${postId}/${candidateId}/profile`,
              search: {
                backRoute,
              },
            })
          }
        >
          <UilUserCircle className="default-icon-sm" /> Profile
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onNextStepClick(candidateId)}
          disabled={!permissions?.manage_pipelines}
        >
          <UilSuitcase className="default-icon-sm" />
          Next Steps
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onRejectClick(candidateId)}
          disabled={!permissions?.manage_pipelines}
        >
          <UilTrashAlt className="default-icon-sm" />
          Reject
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PipelineActionButton;
