import { UilArrowGrowth, UilArrowUp, UilInfoCircle } from '@iconscout/react-unicons';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

const MatchPercentInfo = () => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant="icon"
          className="size-[1.125rem]"
        >
          <UilInfoCircle className="size-[1.125rem] text-primary-dark-40" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center gap-4">
            <div className="rounded-lg border border-primary-dark-10 p-2">
              <UilInfoCircle className="size-5 text-primary-dark-100" />
            </div>
            <span>Match score</span>
          </AlertDialogTitle>
          <AlertDialogDescription className="space-y-8">
            <p>
              Matching a role that’s right for you is more than just CV’s and job descriptions. Upplft goes above and
              beyond to find a match percentage based on your profile, preferences, skills, interests, values and much
              more.
            </p>
            <div className="space-y-4">
              <Card className="space-y-4 border px-4 py-3">
                <Badge
                  hideDot
                  variant="success"
                  className={'pl-1.5 pr-2.5'}
                >
                  <UilArrowUp className="size-4" />
                  70% or higher match
                </Badge>
                <p className="text-sm">This role is an excellent fit for your profile. We highly recommend applying.</p>
              </Card>
              <Card className="space-y-4 border px-4 py-3">
                <Badge
                  hideDot
                  variant="warning"
                  className={'pl-1.5 pr-2.5'}
                >
                  <UilArrowGrowth className="size-4" />
                  Under 70% match
                </Badge>
                <p className="text-sm">This role has some compatibility with your profile. Consider applying.</p>
              </Card>
              {/* <Card className="space-y-4 border px-4 py-3">
                <MatchPercentLabel percent={32} />
                <p className="text-sm">This role does not match your profile..</p>
              </Card> */}
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MatchPercentInfo;
