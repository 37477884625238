import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilSuitcase } from '@iconscout/react-unicons';
import { addYears } from 'date-fns/addYears';
import { endOfYear } from 'date-fns/endOfYear';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
// import Avatar from '@/components/ui/avatar';
// import { Button } from '@/components/ui/button';
import DatePicker from '@/components/ui/date-picker';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Select } from '@/components/ui/select-new';
import TeamMemberSelect, { TTeamMember } from '@/components/ui/team-member-select';
import { toast } from '@/components/ui/toaster';

import { useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';
import { useCreateMeetingDraft } from '@/hooks/meetings';
import { usePipelineActions } from '@/hooks/pipeline/pipeline.async';

import { TCurrentBusiness } from '@/services/business';
import { TCandidateMatchStatus } from '@/services/candidate';
import {
  PIPELINE_ENDPOINTS,
  ROLE_PAY_RATE_TYPE,
  ROLE_PAY_RATE_TYPE_OPTIONS,
  TCandidateForPipeline,
} from '@/services/pipeline';

import { currencyList, formatCurrency, parseCurrency } from '@/utils/currency';

const offerSchema = z.object({
  payType: z.nativeEnum(ROLE_PAY_RATE_TYPE),
  currency: z.string(),
  amount: z.number().min(0),
  startDate: z.date(),
  endDate: z.date(),
  // requestReferences: z.boolean(), // NOTE: Removed references as per request
});

export type TOfferFormData = z.infer<typeof offerSchema>;

const interviewSchema = z.object({
  stage: z.string(),
  stageOwner: z.custom<TTeamMember>().refine((val) => !!val.id, {
    message: 'Stage Owner is required',
  }),
  members: z.array(z.custom<TTeamMember>()),
});

export type TInterviewFormData = z.infer<typeof interviewSchema>;

export type TNextStepModalStatus = Extract<TCandidateMatchStatus, 'INTERVIEWING' | 'OFFER'>;
// Used for either interview or offer
interface INextStepModalProps {
  candidate: TCandidateForPipeline;
  originalCandidateStatus?: TCandidateMatchStatus;
  business?: TCurrentBusiness;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onInterviewSuccess: (data: TInterviewFormData) => void;
  onOfferSuccess: (data: TOfferFormData) => void;
  onInterviewCancel: () => void;
  onOfferCancel: () => void;
  status?: TNextStepModalStatus;
}

const NextStepModal: React.FC<INextStepModalProps> = ({
  candidate,
  originalCandidateStatus,
  business,
  open,
  onOpenChange,
  onInterviewSuccess,
  onOfferSuccess,
  onInterviewCancel,
  onOfferCancel,
  status,
}) => {
  const options = [
    { value: 'INTERVIEWING', label: 'Interview' },
    { value: 'OFFER', label: 'Offer' },
  ];

  const [selectedStatus, setSelectedStatus] = useState<TNextStepModalStatus | undefined>(
    status ? status : 'INTERVIEWING'
  );

  const offerForm = useForm<TOfferFormData>({
    resolver: zodResolver(offerSchema),
    defaultValues: {
      payType: ROLE_PAY_RATE_TYPE.DAY_RATE,
      currency: 'AUD',
      amount: 0,
    },
  });

  const interviewForm = useForm<TInterviewFormData>({
    resolver: zodResolver(interviewSchema),
    mode: 'onChange',
    defaultValues: {
      stageOwner: {
        id: candidate?.job_post?.owner?.id.toString(),
        fullName: `${candidate?.job_post?.owner?.first_name} ${candidate?.job_post?.owner?.last_name}`,
        imageUrl: candidate?.job_post?.owner?.image_url,
      },
      members: candidate?.job_post?.members.map((member) => ({
        id: member.id.toString(),
        fullName: `${member.first_name} ${member.last_name}`,
        imageUrl: member.image_url,
      })),
    },
  });
  const queryKey = [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, candidate.job_post.id];
  const { moveToInterview, makeOffer } = usePipelineActions({
    queryKey,
  });

  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
  }, [status]);

  const { mutate: createMeetingDraft } = useCreateMeetingDraft({
    reactQueryOptions: {
      onSuccess() {
        toast.success('Meeting draft created successfully');
      },
    },
  });

  const handleMoveToInterview = async (data: TInterviewFormData) => {
    if (candidate && business?.id && originalCandidateStatus) {
      await moveToInterview.mutateAsync({
        params: {
          path: {
            businessId: business.id.toString(),
            postId: candidate.job_post.id.toString(),
            userId: candidate.id.toString(),
          },
          query: {
            from_status: originalCandidateStatus,
          },
        },
        body: {
          /**
           * TODO: Send stage and meeting data here
           */
          datetime: null,
          interview_platform: null,
          meeting_notes: null,
          stage_id: Number(data.stage),
        },
      });
      const memberIds = data.members.map((member) => Number(member.id));
      const partnerMembers =
        candidate.job_post.partners
          .find((partner) => partner.id === candidate.metadata?.referrer_business_id)
          ?.members.map((member) => Number(member.id)) || [];
      createMeetingDraft(
        {
          body: {
            candidate_id: candidate.id,
            business_id: business.id,
            post_id: candidate.job_post.id,
            stage_id: Number(data.stage),
            owner_id: Number(data.stageOwner.id),
            members: [...memberIds, ...partnerMembers],
          },
        },
        {
          onSuccess() {
            onInterviewSuccess(data);
          },
        }
      );
    }
  };

  const handleMakeOffer = (data: TOfferFormData) => {
    if (candidate && business?.id && originalCandidateStatus) {
      makeOffer.mutateAsync(
        {
          params: {
            path: {
              businessId: business.id.toString(),
              postId: candidate.job_post.id.toString(),
              userId: candidate.id.toString(),
            },
            query: {
              from_status: originalCandidateStatus,
            },
          },
          body: {
            /**
             * TODO: Send offer data here
             */
            end_date: data.endDate.toISOString(),
            // is_references_required: data.requestReferences,
            // offer_letter_url: null,
            pay_annual_rate: data.payType === ROLE_PAY_RATE_TYPE.ANNUAL_SALARY ? data.amount : null,
            pay_day_rate: data.payType === ROLE_PAY_RATE_TYPE.DAY_RATE ? data.amount : null,
            pay_hourly_rate: data.payType === ROLE_PAY_RATE_TYPE.HOURLY_RATE ? data.amount : null,
            pay_currency: data.currency,
            pay_type: data.payType,
            start_date: data.startDate.toISOString(),
          },
        },
        {
          onSuccess() {
            onOfferSuccess(data);
          },
        }
      );
    }
  };

  const dialogActionOnClick = () => {
    if (selectedStatus === 'INTERVIEWING') {
      interviewForm.handleSubmit(
        (data) => {
          console.log('Interview form data:', data);
          handleMoveToInterview(data);
        },
        (errors) => {
          console.log('Validation errors:', errors);
          /**
           * Handle errors
           */
        }
      )();
    } else if (selectedStatus === 'OFFER') {
      offerForm.handleSubmit(
        (data) => {
          console.log('Offer form data:', data);
          handleMakeOffer(data);
        },
        (errors) => {
          console.log('Validation errors:', errors);
          /**
           * Handle errors
           */
        }
      )();
    } else {
      toast.error('Invalid action');
    }
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onOfferCancel?.();
          onInterviewCancel?.();
        }
        onOpenChange(open);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            <div className="flex items-center gap-4">
              <span className="rounded-lg border border-primary-dark-10 p-1.5">
                <UilSuitcase className="size-5 text-primary-dark-100" />
              </span>
              Next Steps
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription>
            Please select one of the following options for the next steps of this candidate.
          </AlertDialogDescription>
          <div className="space-y-4">
            <div className="flex flex-col gap-2">
              <Label>Status</Label>
              <Select
                options={options}
                selected={options.find((option) => option.value === selectedStatus)!.value}
                onChange={(value) => {
                  setSelectedStatus(value as TNextStepModalStatus);
                }}
              />
            </div>
            {selectedStatus === 'INTERVIEWING' ? (
              <InterviewStep
                form={interviewForm}
                stages={candidate?.job_post?.stages || []}
                owner={candidate?.job_post?.owner}
              />
            ) : (
              <OfferStep form={offerForm} />
            )}
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              if (selectedStatus === 'INTERVIEWING') onInterviewCancel();
              else onOfferCancel();
            }}
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={dialogActionOnClick}
            disabled={
              selectedStatus === 'INTERVIEWING' ? !interviewForm.formState.isValid : !offerForm.formState.isValid
            }
          >
            {selectedStatus === 'INTERVIEWING' ? 'Send notification' : 'Send'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

interface InterviewStepProps {
  form: UseFormReturn<TInterviewFormData>;
  stages: TCandidateForPipeline['job_post']['stages'];
  owner: TCandidateForPipeline['job_post']['owner'];
}

const InterviewStep: React.FC<InterviewStepProps> = ({ form, stages }) => {
  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = form;

  const stagesOptions = stages
    .filter((stage) => !stage.is_prescreening)
    .map((stage) => ({ value: stage.id?.toString(), label: stage.stage_name }));

  const { data: currentBusiness } = useGetCurrentBusiness({});

  const { data: teamMembersData, isPending: isLoadingTeamMembers } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: currentBusiness?.id.toString() ?? '',
      },
      query: {
        sort: 'last_active',
      },
    },
    reactQueryOptions: {
      enabled: !!currentBusiness,
    },
  });

  const teamMembersOptions = teamMembersData?.items?.map((member) => ({
    id: member.id.toString(),
    fullName: `${member.first_name} ${member.last_name}`,
    imageUrl: member.image_url,
  }));

  const stageOwner = watch('stageOwner');
  useEffect(() => {
    if (stageOwner) {
      const currentMembers = getValues('members') || [];
      const updatedMembers = currentMembers.filter((member) => member.id !== stageOwner.id);

      if (updatedMembers.length !== currentMembers.length) {
        setValue('members', updatedMembers, { shouldValidate: true });
      }
    }
  }, [stageOwner, setValue, getValues]);

  /* const renderShowAvailability = () => {
    if (stageOwner.id === owner.id.toString()) {
      return (
        <div className="flex items-center justify-between rounded-lg border border-primary-dark-10 px-4 py-3">
          <div className="flex items-center gap-2">
            <Avatar
              src={stageOwner.imageUrl}
              size="sm"
            />
            <span className="text-sm font-medium">{stageOwner.fullName}</span>
          </div>
          <Button variant="tertiary">Set availability</Button>
        </div>
      );
    }
    return null;
  }; */

  return (
    <FormProvider {...form}>
      <Form className="space-y-4">
        <FormField
          control={control}
          name="stage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Stage</FormLabel>
              <FormControl>
                <Select
                  options={stagesOptions || []}
                  selected={field.value}
                  onChange={field.onChange}
                  error={!!errors?.stage?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="stageOwner"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Stage owner</FormLabel>
              <FormControl>
                <TeamMemberSelect
                  selected={field.value}
                  options={teamMembersOptions ?? []}
                  isLoading={isLoadingTeamMembers}
                  onChange={(value) => {
                    if (value && 'id' in value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  error={!!errors?.stageOwner}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />

        <FormField
          name="members"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Team Members</FormLabel>
              <FormControl>
                <TeamMemberSelect
                  selected={field.value}
                  options={teamMembersOptions?.filter((member) => member.id !== stageOwner?.id) ?? []}
                  isLoading={isLoadingTeamMembers}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      return field.onChange(value);
                    }
                    return field.onChange([]);
                  }}
                  isMulti
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
    </FormProvider>
  );
};

interface OfferStepProps {
  form: UseFormReturn<TOfferFormData>;
}

const OfferStep: React.FC<OfferStepProps> = ({ form }) => {
  const {
    watch,
    formState: { errors },
    control,
  } = form;

  const selectedCurrency = watch('currency');
  return (
    <FormProvider {...form}>
      <Form className="space-y-4">
        <FormField
          control={form.control}
          name="payType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Pay Type</FormLabel>
              <FormControl>
                <Select
                  options={ROLE_PAY_RATE_TYPE_OPTIONS}
                  selected={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <div className="grid grid-cols-[2fr_8fr] gap-4">
          <FormField
            control={control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Currency</FormLabel>
                <FormControl>
                  <Select
                    className="flex"
                    options={currencyList.map((s) => ({ value: s.label, label: s.label }))}
                    selected={field.value}
                    placeholder="Currency"
                    onChange={(value) => field.onChange(value)}
                    error={!!errors?.currency?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            name="amount"
            control={control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>From</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full"
                    placeholder="Enter amount"
                    {...field}
                    value={formatCurrency(field.value, selectedCurrency)}
                    onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                    error={!!errors.amount?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-4">
          <FormField
            control={control}
            name="startDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start Date</FormLabel>
                <FormControl>
                  <DatePicker
                    selected={field.value}
                    onChange={field.onChange}
                    showYearDropdown
                    error={!!errors?.startDate?.message}
                    placeholderText="DD/MM/YYYY"
                    maxDate={endOfYear(addYears(new Date(), 2))}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="endDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>End Date</FormLabel>
                <FormControl>
                  <DatePicker
                    selected={field.value}
                    onChange={field.onChange}
                    showYearDropdown
                    error={!!errors?.endDate?.message}
                    placeholderText="DD/MM/YYYY"
                    maxDate={endOfYear(addYears(new Date(), 2))}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        {/* NOTE: Removed references and file upload as per request */}
        {/* <FormField
          name="requestReferences"
          control={control}
          render={({ field }) => (
            <Checkbox
              id="requestReferences"
              checked={field.value}
              onCheckedChange={field.onChange}
              label="Request references"
            />
          )}
        />
        <FileUploaderWithDragAndDrop
          acceptedFileTypes={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          keyPrefix={``}
          onFileUpload={() => {}}
        /> */}
      </Form>
    </FormProvider>
  );
};

export default NextStepModal;
