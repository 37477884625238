import { Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';

import BackButton from '@/components/back-button';
import { RemoveTeamMemberButton } from '@/components/business/team/remove-member-modal';
import { ResendInviteButton } from '@/components/business/team/resend-invite-modal';
import { ResetPasswordButton } from '@/components/business/team/reset-password-modal';
import FormLoader from '@/components/forms/form-loader';
import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { useGetBusinessTeamMembersDetailsSuspense, useGetCurrentBusinessSuspense } from '@/hooks/business';
import { useGetCurrentBusinessPermissions } from '@/hooks/permission';
import { useGetCurrentUser } from '@/hooks/user';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/team/$profile')({
  component: TeamMermberProfileLayout,
});

function TeamMemberHeader() {
  const { data: currentUser } = useGetCurrentUser({});
  const { data: business } = useGetCurrentBusinessSuspense({});
  const { data: permissions } = useGetCurrentBusinessPermissions();

  const { profile: memberId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetailsSuspense({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!business,
    },
  });

  const isCurrentUser = currentUser?.id === parseInt(memberId);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <BackButton backRoute={'/business/team'} />
        <div className="flex space-x-6">
          <ResetPasswordButton
            id={memberId}
            email={memberProfile?.email as string}
            disableTrigger={!permissions?.manage_team_members}
            hideTrigger={isCurrentUser}
          />
          <ResendInviteButton
            id={memberId}
            email={memberProfile?.email as string}
            disableTrigger={!permissions?.manage_team_members}
            hideTrigger={isCurrentUser}
          />
          <RemoveTeamMemberButton
            id={memberId}
            disableTrigger={!permissions?.manage_team_members}
            hideTrigger={isCurrentUser}
          />
        </div>
      </div>
      <div className="flex w-full items-center gap-x-4 py-8">
        <Avatar
          fallbackType="user"
          src={memberProfile?.image_url}
          size="3xl"
        />
        <div>
          <h1 className="text-3xl font-semibold">
            {memberProfile?.first_name} {memberProfile?.last_name}
          </h1>
          <p className="text-sm text-primary-dark-60">{memberProfile?.email}</p>
        </div>
      </div>
    </div>
  );
}

function TeamMermberProfileLayout() {
  const navItems: ITabNavItem[] = [
    {
      label: 'Profile',
      href: `/partner/team/$profile`,
    },
    {
      label: 'Permissions',
      href: `/partner/team/$profile/permissions`,
    },
  ];
  return (
    <div>
      <TeamMemberHeader />
      <TabNav
        navItems={navItems}
        className="my-8"
      />
      <Suspense fallback={<FormLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
