import { useMemo, useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { UilSuitcaseAlt } from '@iconscout/react-unicons';
import { Link, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tag } from '@/components/ui/tag';

import NoData from '@/components/no-data';
import CloseRoleModal from '@/components/roles/business/close-role-modal';
import DeleteDraftModal from '@/components/roles/business/delete-draft-modal';
import RolesActionButton from '@/components/roles/business/roles-action-button';
import LocationTag from '@/components/tags/location-tag';
import { MatchStatusTag } from '@/components/tags/match-status-tag';

import { useGetCurrentBusiness, useGetCurrentRoleBusiness } from '@/hooks/business';
import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';
import { useGetCurrentDashboard, useGetCurrentUser } from '@/hooks/user';

import { TGetAllBusinessRolesV2RequestStatus } from '@/services/business-roles';
import { USER_TYPE } from '@/services/user';

import { getEmptyBusinessRolesDescription } from '@/utils/role';
import { capitalizeFirstLetter } from '@/utils/string';
import { getPipelineUrl, getRoleDetailUrl } from '@/utils/urls';

import { JOB_POST_STATUS } from '@/constants/post';
import { DEFAULT_PAGE_INDEX } from '@/constants/table';

import CreateRoleButton from '../../roles/business/create-role/create-role-button';

const paginationState = {
  pageIndex: DEFAULT_PAGE_INDEX,
  pageSize: 5,
};

const RolesDashboardTable = ({ isForPartner = false }: { isForPartner?: boolean }) => {
  const navigate = useNavigate();
  const dashboard = useGetCurrentDashboard();
  const { setData: setPipelineData } = usePipelineStore();

  const [activeStatus, setActiveStatus] = useState('all');
  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);
  const [openCloseRoleModal, setOpenCloseRoleModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);

  const business = useGetCurrentRoleBusiness();
  const { data: currentBusiness, isPending: isCurrentBusinessPending } = useGetCurrentBusiness({});

  const [pagination, setPagination] = useState(paginationState);

  const { data: currentUser } = useGetCurrentUser({});

  const { data, isFetching } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        partnerId: isForPartner ? String(currentBusiness?.id) : '',
        limit: pagination.pageSize.toString(),
        offset: (pagination.pageIndex * pagination.pageSize).toString(),
        status: activeStatus as TGetAllBusinessRolesV2RequestStatus,
      },
    },
    reactQueryOptions: {
      enabled: !!business && !!currentBusiness,
      queryKey: ['dashboard-roles', activeStatus, pagination],
    },
  });

  const rolesLink = useMemo(() => {
    if (currentUser?.user_type === USER_TYPE.PARTNER) {
      return `/partner/roles`;
    } else {
      return `/business/roles`;
    }
  }, [currentUser]);

  const tabs: {
    label: string;
    count?: number | null;
    status: TGetAllBusinessRolesV2RequestStatus;
  }[] = [
    {
      label: 'All',
      count: data?.metadata?.total_posts || 0,
      status: 'all',
    },
    {
      label: 'Open',
      count: data?.metadata?.total_open_posts || 0,
      status: 'active',
    },
    {
      label: 'Paused',
      count: data?.metadata?.total_paused_posts || 0,
      status: 'hold',
    },
    {
      label: 'Closed',
      count: data?.metadata?.total_closed_posts || 0,
      status: 'closed',
    },
    {
      label: 'Drafts',
      count: data?.metadata?.total_draft_posts || 0,
      status: 'draft',
    },
  ];

  const handleActiveStatusChange = (newStatus: string) => {
    setActiveStatus(newStatus);
    setPagination({ ...pagination, pageIndex: DEFAULT_PAGE_INDEX });
  };

  const renderData = () => {
    const { title, description } = getEmptyBusinessRolesDescription(
      activeStatus as TGetAllBusinessRolesV2RequestStatus
    );
    if (data?.total === 0)
      return (
        <NoData
          title={title}
          description={description}
          className="min-h-full flex-1"
        >
          <CreateRoleButton />
        </NoData>
      );

    if (isFetching || isCurrentBusinessPending) {
      return (
        <div className="space-y-4">
          {new Array(5).fill(0).map((_, index) => (
            <Skeleton
              key={index}
              className="h-[6.5rem] w-full"
            />
          ))}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {data?.items.map((role) => (
          <Card
            key={role.id}
            className="flex items-center justify-between gap-4"
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <UilSuitcaseAlt className="default-icon-sm" />
                <h3 className="font-semibold">{role.title}</h3>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <MatchStatusTag jobPostStatus={role.status as JOB_POST_STATUS} />
                {role.workplace_type && <Tag variant="primary">{capitalizeFirstLetter(role.workplace_type)}</Tag>}
                {role.employment_type && <Tag variant="primary">{role.employment_type?.split(' ')[0]}</Tag>}
                <LocationTag location={role.location!} />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Button variant="link">
                <Link
                  to={getRoleDetailUrl({
                    dashboard: dashboard,
                    roleId: role.id,
                  })}
                >
                  View role
                </Link>
              </Button>
              {role.status === 1 && (
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setPipelineData([
                      {
                        id: role.id,
                        title: role.title,
                      },
                    ]);
                    navigate({ to: getPipelineUrl({ dashboard }) });
                  }}
                >
                  View Pipeline
                </Button>
              )}
              <RolesActionButton
                roleId={role.id}
                status={role.status as JOB_POST_STATUS}
                onDeleteClick={() => {
                  setSelectedRoleId(role.id);
                  setOpenDeleteDraftModal(true);
                }}
                onCloseClick={() => {
                  setSelectedRoleId(role.id);
                  setOpenCloseRoleModal(true);
                }}
              />
            </div>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>
      <Card className="flex min-h-[82.5dvh] flex-col gap-6">
        <div className="flex justify-between">
          <h3 className="text-xl font-semibold">Roles</h3>
          <div className="flex items-center gap-4">
            <Link to={rolesLink}>
              <Button variant="tertiary">View all</Button>
            </Link>
            <CreateRoleButton />
          </div>
        </div>
        <Tabs
          value={activeStatus}
          onValueChange={handleActiveStatusChange}
        >
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.label}
                value={tab.status ?? ''}
                className="inline-flex min-w-[7rem] items-center gap-x-2 text-sm 2xl:min-w-[8rem] 3xl:min-w-[9.5rem] 3xl:text-base"
              >
                {tab.label}
                {(tab.count || tab.count === 0) && (
                  <span className="inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded border border-[#e7e9ed] p-0.5">
                    <span className="text-center font-['Montserrat'] text-xs font-medium leading-[18px] text-[#0c2149]">
                      {tab.count}
                    </span>
                  </span>
                )}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <div className="flex h-full flex-1 flex-col">{renderData()}</div>
      </Card>
      {selectedRoleId && (
        <>
          <DeleteDraftModal
            open={openDeleteDraftModal}
            onOpenChange={setOpenDeleteDraftModal}
            roleId={selectedRoleId}
          />
          <CloseRoleModal
            roleId={selectedRoleId}
            open={openCloseRoleModal}
            onOpenChange={setOpenCloseRoleModal}
          />
        </>
      )}
    </>
  );
};

export default RolesDashboardTable;
