import { Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';
import Separator from '@/components/ui/separator';

import BackButton from '@/components/back-button';
import { RemovePartnerButton } from '@/components/business/partner/remove-partner-modal';
import { ResendInviteButton } from '@/components/business/partner/resend-invite-modal';
import FormLoader from '@/components/forms/form-loader';
import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { useGetBusinessPartnersDetailsSuspense, useGetCurrentBusinessSuspense } from '@/hooks/business';
import { useGetCurrentUserPermissions } from '@/hooks/permission';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId')({
  component: PartnersLayout,
});

function PartnerHeader() {
  const { data: business } = useGetCurrentBusinessSuspense({});
  const { data: permissions } = useGetCurrentUserPermissions();
  const { partnerId } = Route.useParams();
  const { data: partnerProfile } = useGetBusinessPartnersDetailsSuspense({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        partnerId: partnerId,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerId && !!business,
    },
  });
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <BackButton backRoute={'/business/partners'} />
        <div className="flex space-x-6">
          <ResendInviteButton
            id={partnerId}
            email={partnerProfile?.owner?.email as string}
          />
          {/* TODO: Enable/Disable Partner */}
          {/* <EnableDisableButton /> */}
          <RemovePartnerButton
            id={partnerId}
            hideTrigger={!permissions?.manage_partners}
          />
        </div>
      </div>
      <div className="flex w-full items-center gap-x-4 py-8">
        <Avatar
          fallbackType="company"
          src={partnerProfile?.logo_url}
          size="3xl"
        />
        <div>
          <h1 className="text-3xl font-semibold">{partnerProfile?.name}</h1>
        </div>
      </div>
    </div>
  );
}

function PartnersLayout() {
  const navItems: ITabNavItem[] = [
    {
      label: 'Placements',
      href: `/business/partners/$partnerId/placements`,
      fuzzyMatch: true,
    },
    {
      label: 'Profile',
      href: `/business/partners/$partnerId/profile`,
    },
    {
      label: 'Permissions',
      href: `/business/partners/$partnerId/permissions`,
    },
  ];
  return (
    <div>
      <PartnerHeader />
      <TabNav
        navItems={navItems}
        className="my-8"
      />
      <Separator />
      <Suspense fallback={<FormLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
