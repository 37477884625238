import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import Avatar from '../ui/avatar';

type ProfileBubbleProps = {
  id: number;
  name?: string;
  image_url?: string | null;
  fallbackType?: 'company' | 'user';
};

interface IProfileBubblesProps {
  bubbles: ProfileBubbleProps[];
  maxBubbles?: number;
}

export function ProfileBubbles({ bubbles, maxBubbles = 4 }: IProfileBubblesProps) {
  if (!bubbles?.length) {
    return null;
  }
  const hasMore = bubbles.length > maxBubbles;
  const thisManymore = bubbles.length - maxBubbles;

  return (
    <div className="flex items-center">
      {bubbles.slice(0, maxBubbles).map((bubble, i) => (
        <div key={i}>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div
                  className="relative overflow-hidden"
                  style={{
                    marginRight: i !== maxBubbles ? -8 : 0,
                    zIndex: maxBubbles - i,
                  }}
                >
                  <Avatar
                    fallbackType={bubble.fallbackType}
                    src={bubble.image_url}
                    size={'md'}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent className="rounded-xl bg-primary-dark-100 px-3 py-2 text-xs text-primary-white-100">
                <h4 className="font-semibold leading-4">{bubble.name}</h4>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ))}
      {hasMore && (
        <div className="relative">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-dark-100 text-primary-white-100">
                  +{thisManymore}
                </div>
              </TooltipTrigger>
              <TooltipContent className="rounded-xl bg-primary-dark-100 px-3 py-2 text-xs text-primary-white-100">
                <h4 className="font-semibold leading-4">+{thisManymore} more</h4>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </div>
  );
}
