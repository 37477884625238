import { createFileRoute, redirect } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';

import { BusinessInvitationForm } from '@/components/forms/invitation/business-invitation-form';

import { useBusinessInviteSetupVerifyToken } from '@/hooks/business';

import { businessInviteSetupVerifyToken } from '@/services/business';

export const Route = createFileRoute('/invite/$token/')({
  component: InvitationPage,
  beforeLoad: async ({ params }) => {
    const token = params.token;
    try {
      const isTokenValid = await businessInviteSetupVerifyToken({ params: { path: { token } } });
      if (!isTokenValid?.success) {
        throw new Error('Invalid token');
      }
    } catch (error) {
      return redirect({ to: '/invite/invalid-token' });
    }
  },
});

function InvitationPage() {
  const { token } = Route.useParams();
  const { data } = useBusinessInviteSetupVerifyToken({ params: { path: { token } } });
  return (
    <div className={'flex justify-center py-8'}>
      <Card className="w-[32.75rem] justify-center p-8 shadow-lg">
        <BusinessInvitationForm
          first_name={data?.data.first_name || ''}
          last_name={data?.data.last_name || ''}
        />
      </Card>
    </div>
  );
}
