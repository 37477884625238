import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardTitle } from '@/components/ui/card';
import Confetti from '@/components/ui/confetti';

import FeaturedIcon from '@/components/icons/featured-icon';

export const Route = createFileRoute('/_authenticated/business/onboarding/completed')({
  component: OnboardingComponent,
});

function OnboardingComponent() {
  const navigate = useNavigate();

  return (
    <main className="flex h-svh w-full items-center justify-center">
      <Confetti />
      <Card className="max-w-[32.75rem] rounded-2xl p-8 shadow-2xl">
        <CardTitle className="flex items-center gap-3.5">
          <FeaturedIcon variant="success" />
          <span className="text-2xl">Well done</span>
        </CardTitle>
        <CardContent className="py-4 text-primary-dark-60">
          You have successfully completed your onboarding!
          <br />
          <br />
          Share the load and invite your team members or get straight into posting your first available role.
        </CardContent>
        <CardFooter className="gap-4">
          <Button
            onClick={() => navigate({ to: '/business/team' })}
            variant={'tertiary'}
          >
            Invite team
          </Button>
          <Button onClick={() => navigate({ to: '/business' })}>Post role</Button>
        </CardFooter>
      </Card>
    </main>
  );
}
