import { useState } from 'react';
import { UilFastMail, UilPlus } from '@iconscout/react-unicons';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import Confetti from '@/components/ui/confetti';

import { InvitePartnerForm } from '@/components/forms/business/partner/invite-partner-form';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

const InvitePartnerButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { data: permissions } = useGetCurrentUserPermissions();
  const isInviteButtonDisabled = disabled || !permissions?.manage_team_members;

  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setIsSuccess(true);
  };

  const renderContent = () => {
    if (isSuccess) {
      return (
        <>
          <AlertDialogDescription>
            Your invite has been sent. You will receive a notification when it has been accepted.
            <br />
            <br />
            Manage user permissions in &apos;Partners&apos;.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setOpen(false)}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </>
      );
    }

    return (
      <>
        <AlertDialogDescription className="py-0">
          Invite your partners or recruitment agencies to your company page.
        </AlertDialogDescription>
        <InvitePartnerForm onInviteSent={handleSubmit} />
      </>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setIsSuccess(false);
        }
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          disabled={isInviteButtonDisabled}
          className="w-full"
          size={'sm'}
        >
          <UilPlus size={'1.125rem'} />
          <span>Invite partner</span>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        {isSuccess && <Confetti usePortal />}
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={isSuccess ? 'success' : null}>
            {isSuccess ? (
              'Invite sent!'
            ) : (
              <div className="flex items-center gap-2">
                <UilFastMail /> <span>Invite partners</span>
              </div>
            )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        {renderContent()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InvitePartnerButton;
